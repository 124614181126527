import styled from 'styled-components';
import { Table } from 'antd';
import { mediaMax } from 'common/theme';
import { checkBox } from 'components/form/Checkbox.styles';

export const ScRotatingLoaderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.3;
  }
`;

export const ScTableWrap = styled.div`
  position: relative;
`;

export const ScTable = styled(Table)<{
  $maxWidths: [number, string][];
  $minWidths: [number, string][];
}>`
  ${({ $maxWidths }) => {
    return $maxWidths.map(
      ([idx, width]) => `
        td:nth-child(${idx + 1}),
        th:nth-child(${idx + 1}) {
          max-width: ${width};
        }`,
    );
  }}

  ${({ $minWidths }) => {
    return $minWidths.map(
      ([idx, width]) => `
        td:nth-child(${idx + 1}),
        th:nth-child(${idx + 1}) {
          min-width: ${width};
        }`,
    );
  }}

  &.loading {
    filter: blur(2px);
  }

  &.modal-table.ant-table-wrapper {
    border-top: 0;
    padding-left: 0;

    .ant-table {
      font-size: inherit;
      border-bottom: none;
    }
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-table {
    font-size: inherit;
  }

  &.ant-table-wrapper {
    .ant-spin-dot-item {
      background-color: ${({ theme }) => theme.mainColor};
    }

    .ant-table-cell-ellipsis:not(th) {
      cursor: pointer;
      color: ${({ theme }) => theme.mainColor};

      > span {
        color: ${({ theme }) => theme.textColor};
      }
    }
    .ant-radio-checked .ant-radio-inner,
    .ant-radio .ant-radio-inner {
      border-color: ${({ theme }) => theme.mainBorderColor};
      width: 1.5em;
      height: 1.5em;

      &::after {
        content: '\\2713';
        width: 1.375em;
        height: 1.375em;
        background-color: ${({ theme }) => theme.mainColor};
        color: ${({ theme }) => theme.mainColor_contrast};
        border-radius: 1.875em;
        top: 0;
        left: 0;
        line-height: 1.25em;
        transition: linear;
      }
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${({ theme }) => theme.mainBorderColor};
    }

    .ant-table-thead > tr > th.ant-table-cell,
    .ant-table tfoot > tr > th.ant-table-cell {
      padding: 0 0.5em;
      overflow-wrap: inherit;
      border-color: ${({ theme }) => theme.colors.softPeach};

      &:first-child {
        padding: 0;
      }
    }

    .ant-table-thead .ant-table-cell.ant-table-selection-column .ant-table-selection {
      display: none;
    }

    .ant-table-cell.ant-table-selection-column {
      padding-left: 1em;
    }

    .ant-table {
      border: 1px solid ${({ theme }) => theme.colors.softPeach};
      border-right: none;
      border-left: none;
      border-radius: 0;
      background: ${({ theme }) => theme.colors.white};
    }

    .ant-table ant-table-thead > tr > th {
      padding: 0 0.75em;
    }

    .ant-table-tbody > tr > td {
      padding: 1em 0.5em;

      ${mediaMax.md} {
        padding: 0.7em 0.5em;
      }
    }

    .ant-table-thead > tr > th,
    .ant-table tfoot > tr > th {
      margin: 0;
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      ${checkBox}
    }

    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > td {
      margin: 0;
      font-weight: 600;

      ${checkBox}
    }

    .ant-table-thead > tr > th {
      text-transform: capitalize;
      background: ${({ theme }) => theme.colors.white};
      font-size: 1em;
    }

    .ant-table-tbody > tr.ant-table-row-selected > td.ant-table-cell-fix-right {
      background-color: ${({ theme }) => theme.colors.white};
    }

    .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
      border-left: 1px solid ${({ theme }) => theme.colors.softPeach};
      box-sizing: border-box;
      box-shadow: ${({ theme }) => theme.shadows.formHeader};
    }

    .ant-table-tbody > tr.ant-table-row.option__row {
      font-size: 0.875em;

      &.latest {
        background-color: rgba(236, 222, 222, 0.2);
      }

      &.completed {
        cursor: not-allowed;
      }
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.softPeach};
    }

    .ant-table-column-sorters {
      padding: 0;
      justify-content: center;

      & > span:not(.ant-table-column-sorter) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .ant-table-tbody .row-with-error {
      td {
        background-color: ${({ theme }) => theme.errorBgColor};
      }
      .ant-input-number {
        background-color: transparent;
      }
      > td .ant-checkbox .ant-checkbox-inner {
        &::after {
          background: transparent;
        }
      }
    }

    .ant-table-filter-column {
      margin: 0;

      .ant-table-filter-column-title {
        padding: 0.5em;
        flex-grow: 0;
      }
    }

    .ant-table-column-sorter-up,
    .ant-table-column-sorter-down,
    .ant-table-filter-trigger {
      font-size: 0.9em;
      font-weight: 900;
      color: ${({ theme }) => theme.colors.black};
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active,
    .ant-table-filter-trigger.active {
      color: ${({ theme }) => theme.mainColor};
    }

    .ant-table-filter-trigger-container {
      position: static;

      .ant-table-filter-trigger {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .anticon {
          position: static;
          transform: none;
        }
      }
    }

    .ant-table-cell.without-padding {
      padding: 0;
    }

    .ant-table-cell.extended-image {
      padding-right: 1em;
      text-align: center;
    }
  }
`;

import { DocumentTemplate_GetPDFTemplatesList_Args_Entities } from '@hypercharge/xdms-client/lib/types';
import { Price } from 'components/Price';
import { useCurrency } from 'context/currency/CurrencyProvider';
import { useModelApi } from 'context/model/useModelApi';
import { TotalTableRow } from 'pages/total/components/TotalTableRow';
import { default as React, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import {
  ScTotalImageGalleryWrapper,
  ScTotalPageContainer,
  ScTotalTableContainer,
} from './styles';
import { useDocumentTemplateApi } from 'context/documentTemplate/useDocumentTemplateApi';
import { useStep } from 'context/step/StepProvider';
import { NO_IMAGE_PATH } from 'utils/constants';
import { useSelector } from 'react-redux';
import {
  configurationSelectors,
  documentTemplatesSelectors,
  modelSelectors,
} from 'store';
import AccessoriesDeselected from './sections/Accessories/AccessoriesDeselected';
import AccessoriesOptional from './sections/Accessories/AccessoriesOptional';
import Balance from './sections/Balance';
import BaseMachine from './sections/BaseMachine';
import Buttons from './sections/Buttons';
import ColumnsTitles from './sections/ColumnsTitles/ColumnsTitles';
import OptionsDeselected from './sections/Options/OptionsDeselected';
import OptionsOptional from './sections/Options/OptionsOptional';
import OptionsSelectedNotStandard from './sections/Options/OptionsSelected';
import OptionsSelectedAndStandard from './sections/Options/OptionsSelectedAndStandard';
import OptionsSelectedStandard from './sections/Options/OptionsStandard';
import Packages from './sections/Packages/Packages';
import SalesInfo from './sections/SalesInfo';
import Subtotal from './sections/Subtotal';
import Title from './sections/Title/Title';

import ImageGallery from 'components/ImageGallery';
import { useStreaming } from 'context/streaming/StreamingProvider';
import useCarInfo from 'hooks/useCarInfo';
import { useWindowSize } from 'hooks/useWindowSize';
import Layout from 'layout/Default/Layout';
import { totalDynamicSectionsRenderer } from './sectionsDynamic/totalDynamicSectionsRenderer';
import { TotalPageProvider, useTotalPage } from './TotalPageProvider';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { DocumentRelatedEntityCode } from 'context/document/types';
import { getStreamActions } from './utils/getStreamActions';
import { StreamingEventType } from 'context/streaming/types';
import useStreamingActionsList from 'components/streaming/useStreamingActionsList';
import TradeInsSeparate from './sections/TradeInsSeparate/TradeInsSeparate';

export type MarginChangeParams = {
  value: number;
  type: 'percent' | 'amount' | 'balance';
};

const Total: FC = () => {
  const { t, i18n } = useTranslation();

  const [firstAvailableImage, setFirstAvailableImage] = useState<string | null>(null);
  const [brokenImagesCount, setBrokenImagesCount] = useState<number>(0);
  const imageGalleryRef = useRef<React.ElementRef<typeof ImageGallery> | null>(null);
  const [isRefetchDone, setIsRefetchDone] = useState<boolean>(false);

  const {
    error: carInfoError,
    refetch: refetchCarInfo,
    variables: carInfoVariables,
  } = useCarInfo();

  const { getModel } = useModelApi();
  const { getPDFTemplates } = useDocumentTemplateApi();
  const { currency } = useCurrency();
  const { handlePrevStep, handleNextStep } = useStep();
  const [windowWidth] = useWindowSize();
  const { sendMessage } = useStreaming();
  const { getDocumentsList } = useDocumentsApi();

  const { model, isConfigurationComplete } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);
  const { configurationNumber, configurationModelName } = useSelector(
    configurationSelectors.getAll,
  );
  const pdfTemplates = useSelector(documentTemplatesSelectors.getTemplatesList);

  const {
    isShowHODFeatureEnabled,
    isShowSalesInfoFeatureEnabled,
    isShowOptionalFeatureEnabled,
    isShowDeselectedFeatureEnabled,
    isShowOptionsSplit,
    isAllowTotalDocumentPrintFeatureEnabled,
    isTotalTradeInsSeparateViewFeatureEnabled,

    hideZeroDiscounts,
    hideIfEmpty,
    FIELDS,
    totalData,
    configurationDetailsImages,
    dealerAction,
    isLoading,
    modelId,
    modelPublishAllowed,
    actionsList,
    maxBalanceValue,
    publishConfiguration,
    handleMarginChange,
    printDocument,
    requiredItems,
    bannerSettingsList,
    getPublishedDocumentUrl,
    getSummaryTransparentImage,
  } = useTotalPage();

  useEffect(() => {
    getModel({ shouldRecalculateCo2Emission: true });
    getPDFTemplates({
      entity: DocumentTemplate_GetPDFTemplatesList_Args_Entities.configuration,
    });
  }, [getModel, getPDFTemplates]);

  useEffect(() => {
    if (!imageGalleryRef.current) return;

    const currentImageIndex = imageGalleryRef.current.getCurrentIndex();
    const firstAvailableImageIndex = configurationDetailsImages.findIndex(
      detail => detail.source === firstAvailableImage,
    );
    if (currentImageIndex !== firstAvailableImageIndex) {
      imageGalleryRef.current.slideToIndex(firstAvailableImageIndex);
    }
  }, [firstAvailableImage, configurationDetailsImages]);

  const shouldShowImageGallery = useMemo<boolean>(() => {
    return brokenImagesCount !== configurationDetailsImages.length;
  }, [brokenImagesCount, configurationDetailsImages.length]);

  useEffect(() => {
    if (!carInfoError || isRefetchDone) return;
    refetchCarInfo();
    setIsRefetchDone(true);
  }, [carInfoError, refetchCarInfo, isRefetchDone]);

  const streamingActionsList = useStreamingActionsList(actionsList, bannerSettingsList);

  useEffect(() => {
    if (!configurationNumber) return;

    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: {
            optionType: null,
            token: modelToken,
          },
        },
      });
    }

    getDocumentsList(DocumentRelatedEntityCode.configuration, undefined).then(
      ({ response: documents }) => {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'summary',
            data: {
              fields: [
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.baseMachine.brutto, 0),
                },
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.optionsSelected.netto, 0),
                },
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.packages.netto, 0),
                },
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.accessories.netto, 0),
                },
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.totalExDealer.netto, 0),
                },
                ...getStreamActions(streamingActionsList, FIELDS).map(action => ({
                  ...action,
                  currency: currency,
                })),
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.subtotal.netto, 0),
                },
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.tradeInSubtotal, 0),
                },
                {
                  name: '',
                  currency: currency,
                  value: get(totalData, FIELDS.balance, 0),
                },
              ],
              documentSrc: getPublishedDocumentUrl(documents ?? []),
              imageSrc: getSummaryTransparentImage(),
              configurationModelName,
            },
          },
        });
      },
    );
  }, [
    FIELDS,
    streamingActionsList,
    carInfoVariables,
    configurationDetailsImages,
    configurationModelName,
    configurationNumber,
    currency,
    getDocumentsList,
    getPublishedDocumentUrl,
    getSummaryTransparentImage,
    i18n.language,
    modelToken,
    sendMessage,
    t,
    totalData,
  ]);

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.CHANGE_SCREEN,
      data: {
        screen: 'summary',
      },
    });
  }, [sendMessage]);

  return (
    <Layout>
      <ScTotalPageContainer data-testid="total-page-content-container">
        {shouldShowImageGallery && (
          <ScTotalImageGalleryWrapper>
            <ImageGallery
              ref={imageGalleryRef}
              items={configurationDetailsImages.map(image => ({
                original: image.source || NO_IMAGE_PATH,
                originalHeight: 700,
                thumbnail: image.source || NO_IMAGE_PATH,
                thumbnailHeight: 700,
              }))}
              onImageLoad={event => {
                if (!firstAvailableImage) {
                  setFirstAvailableImage(event.currentTarget.getAttribute('src'));
                }
              }}
              onThumbnailError={() => {
                setBrokenImagesCount(prevCount => prevCount + 1);
              }}
              infinite
              showThumbnails
              showNav
              showFullscreenButton={false}
              showPlayButton={false}
              thumbnailPosition="right"
              thumbnailNoImageHeight={192}
              previewNoImageHeight={700}
            />
          </ScTotalImageGalleryWrapper>
        )}

        <ScTotalTableContainer data-testid="total-page-table-container">
          <Title
            totalData={totalData}
            modelName={configurationModelName ?? ''}
            modelId={modelId}
            FIELDS={FIELDS}
            isShowHODFeatureEnabled={isShowHODFeatureEnabled}
            t={t}
          />
          <ColumnsTitles currency={currency} t={t} windowWidth={windowWidth} />
          <BaseMachine
            totalData={totalData}
            FIELDS={FIELDS}
            t={t}
            hideZeroDiscounts={hideZeroDiscounts}
          />

          {isShowOptionsSplit ? (
            <>
              <OptionsSelectedStandard
                model={model}
                FIELDS={FIELDS}
                t={t}
                totalData={totalData}
                hideZeroDiscounts={hideZeroDiscounts}
                hideIfEmpty={hideIfEmpty}
              />
              <OptionsSelectedNotStandard
                model={model}
                FIELDS={FIELDS}
                t={t}
                totalData={totalData}
                hideZeroDiscounts={hideZeroDiscounts}
                hideIfEmpty={hideIfEmpty}
              />
            </>
          ) : (
            <OptionsSelectedAndStandard
              hideZeroDiscounts={hideZeroDiscounts}
              model={model}
              FIELDS={FIELDS}
              t={t}
              totalData={totalData}
              hideIfEmpty={hideIfEmpty}
            />
          )}

          <Packages
            model={model}
            t={t}
            totalData={totalData}
            FIELDS={FIELDS}
            hideZeroDiscounts={hideZeroDiscounts}
            hideIfEmpty={hideIfEmpty}
          />

          {totalDynamicSectionsRenderer(bannerSettingsList)}

          <Subtotal
            t={t}
            totalData={totalData}
            FIELDS={FIELDS}
            hideZeroDiscounts={hideZeroDiscounts}
          />

          {isTotalTradeInsSeparateViewFeatureEnabled ? (
            <TradeInsSeparate
              model={model}
              t={t}
              totalData={totalData}
              FIELDS={FIELDS}
              hideIfEmpty
            />
          ) : (
            <TotalTableRow
              data-testid="total-page-tradeIn-subtotal"
              borderBottom="thin"
              textSize="large"
              fields={[
                t('TOTAL_HEADER_TRADEIN_SUBTOTAL'),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                <Price
                  key="tradeInSubtotal"
                  value={get(totalData, FIELDS.tradeInSubtotal, 0)}
                />,
              ]}
            />
          )}

          <Balance
            totalData={totalData}
            FIELDS={FIELDS}
            t={t}
            isLoading={isLoading}
            isConfigurationComplete={isConfigurationComplete}
            handleBalanceChange={handleMarginChange}
            currency={currency}
            dealerAction={dealerAction}
            maxValue={maxBalanceValue}
          />

          {isShowOptionalFeatureEnabled && (
            <OptionsOptional
              model={model}
              FIELDS={FIELDS}
              t={t}
              hideZeroDiscounts={hideZeroDiscounts}
              hideIfEmpty={hideIfEmpty}
            />
          )}
          {isShowDeselectedFeatureEnabled && (
            <OptionsDeselected
              model={model}
              FIELDS={FIELDS}
              t={t}
              hideZeroDiscounts={hideZeroDiscounts}
              hideIfEmpty={hideIfEmpty}
            />
          )}
          {isShowOptionalFeatureEnabled && (
            <AccessoriesOptional
              model={model}
              FIELDS={FIELDS}
              t={t}
              hideZeroDiscounts={hideZeroDiscounts}
              hideIfEmpty={hideIfEmpty}
            />
          )}
          {isShowDeselectedFeatureEnabled && (
            <AccessoriesDeselected
              model={model}
              FIELDS={FIELDS}
              t={t}
              hideZeroDiscounts={hideZeroDiscounts}
              hideIfEmpty={hideIfEmpty}
            />
          )}
          {isShowSalesInfoFeatureEnabled && (
            <SalesInfo t={t} totalData={totalData} FIELDS={FIELDS} />
          )}

          <Buttons
            t={t}
            handlePrevStep={handlePrevStep}
            handleNextStep={handleNextStep}
            pdfTemplates={pdfTemplates}
            printDocument={printDocument}
            publishConfiguration={publishConfiguration}
            isConfigurationComplete={isConfigurationComplete}
            modelPublishAllowed={modelPublishAllowed}
            requiredItems={requiredItems}
            isAllowTotalDocumentPrintFeatureEnabled={
              isAllowTotalDocumentPrintFeatureEnabled
            }
          />
        </ScTotalTableContainer>
      </ScTotalPageContainer>
    </Layout>
  );
};

const TotalWrapped: FC = () => (
  <TotalPageProvider>
    <Total />
  </TotalPageProvider>
);

export default TotalWrapped;

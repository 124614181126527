import { OptionWithKey } from 'types/common';

const mapFieldToSelectOption = <T = Record<string, unknown>>(
  option: T,
  field: string | string[],
  separator: string,
): string => {
  return Array.isArray(field)
    ? field.reduce<string>((res, item, index) => {
        if (index !== 0) {
          res += separator;
        }
        res += option[item];
        return res;
      }, '')
    : option[field];
};

export const mapToSelectOptions = <T = Record<string, unknown>>(
  options: T[],
  {
    label,
    value,
    separator = '',
    disabledOptions = [],
  }: {
    label: string | string[];
    value: string | string[];
    separator?: string;
    disabledOptions?: string[];
  },
): OptionWithKey[] => {
  if (!(options && options.length)) {
    return [];
  }

  return options.map(option => ({
    label: mapFieldToSelectOption(option, label, separator),
    value: mapFieldToSelectOption(option, value, separator),
    key: (Math.random() + 1).toString(36).substring(7),
    disabled: disabledOptions.includes(mapFieldToSelectOption(option, value, separator)),
  }));
};

import { FinancingCustomer, FinancingCustomerFields } from 'types/vendor';

export const getInitialValues = (
  initials?: Partial<FinancingCustomer>,
): FinancingCustomer =>
  ({
    [FinancingCustomerFields.name]: '',
    [FinancingCustomerFields.name2]: '',
    [FinancingCustomerFields.address]: '',
    [FinancingCustomerFields.address2]: '',
    [FinancingCustomerFields.zip]: '',
    [FinancingCustomerFields.city]: '',
    [FinancingCustomerFields.country]: '',
    [FinancingCustomerFields.phone]: '',
    [FinancingCustomerFields.birthDate]: '',
    [FinancingCustomerFields.language]: '',
    [FinancingCustomerFields.phoneMobile]: '',
    [FinancingCustomerFields.email]: '',
    [FinancingCustomerFields.typeOfUse]: '',
    [FinancingCustomerFields.inPersonType]: '',
    [FinancingCustomerFields.vat]: '',
    [FinancingCustomerFields.isCompany]: false,
    ...initials,
  } as FinancingCustomer);

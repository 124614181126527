import Checkbox from 'components/form/Checkbox';
import { InputNumber } from 'components/form/InputNumber';
import Table from 'components/table';
import styled from 'styled-components';

export const ScBadgeContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

export const ScBadge = styled.div`
  position: absolute;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 100%;
  background-color: ${props => props.theme.mainColor};
  width: 1em;
  height: 1em;
  z-index: 5;
  font-size: 0.625em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  transform: translate(0.688em, -0.688em);
  box-sizing: content-box;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    border: ${props => `1px solid ${props.theme.mainColor_contrast}`};
  }
`;

export const ScMenuItemContent = styled.span`
  font-size: 0.75em;
  line-height: 1em;
`;

export const ScInputNumber = styled(InputNumber)`
  max-width: 10em;

  &.ant-input-number {
    padding-right: 0;
    height: 100%;

    .ant-input-number-input-wrap {
      height: 100%;

      input {
        height: 100%;
      }
    }
  }
`;

export const ScTable = styled(Table)`
  padding-bottom: 200px;

  /* prevents table header items from collapsing text when header sticky and horizontal scroll are enabled */
  .ant-table-content table {
    width: unset !important;
  }

  && {
    .ant-table-thead > tr > th.ant-table-cell,
    .ant-table tfoot > tr > th.ant-table-cell {
      padding: 0.75em 0.5em;
    }
  }
`;

export const ScCheckbox = styled(Checkbox)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
`;

import { SoftOfferPaymentFields, SoftOfferPayment } from 'types/vendor';

export const getInitialPaymentValues = (): SoftOfferPayment =>
  ({
    [SoftOfferPaymentFields.id]: 0,
    [SoftOfferPaymentFields.configurationNumber]: '',
    [SoftOfferPaymentFields.changedAt]: null,
    [SoftOfferPaymentFields.changedBy]: '',

    [SoftOfferPaymentFields.paymentType]: '',
    [SoftOfferPaymentFields.documentCustomer]: '',
    [SoftOfferPaymentFields.documentDealer]: '',
    [SoftOfferPaymentFields.documentResignationLetter]: '',
    [SoftOfferPaymentFields.letterFileName]: '',
  } as SoftOfferPayment);

import {
  SoftOfferAcceptionQuestionsFields,
  SoftOfferAcceptionQuestions,
} from 'types/vendor';

export const getInitialValues = (): SoftOfferAcceptionQuestions =>
  ({
    [SoftOfferAcceptionQuestionsFields.id]: 0,
    [SoftOfferAcceptionQuestionsFields.changedAt]: null,
    [SoftOfferAcceptionQuestionsFields.changedBy]: '',

    [SoftOfferAcceptionQuestionsFields.isInsuranceRefusedPreviously]: false,
    [SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReasonCode]: '',
    [SoftOfferAcceptionQuestionsFields.insuranceRefusedPreviouslyReason]: '',
    [SoftOfferAcceptionQuestionsFields.isDeductedPreviously]: false,
    [SoftOfferAcceptionQuestionsFields.deductedPreviouslyReason]: '',
    [SoftOfferAcceptionQuestionsFields.isThirdPartyInsured]: false,
    [SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceCode]: '',
    [SoftOfferAcceptionQuestionsFields.thirdPartyInsuranceNumber]: '',
    [SoftOfferAcceptionQuestionsFields.isDaysTrackIndicatorEnabled]: false,
    [SoftOfferAcceptionQuestionsFields.isBannedFromDrivingRecently]: false,
    [SoftOfferAcceptionQuestionsFields.bannedFromDrivingRecentlyReason]: '',
    [SoftOfferAcceptionQuestionsFields.isRestrictedInDrivingRecently]: false,
    [SoftOfferAcceptionQuestionsFields.restrictedInDrivingRecentlyReason]: '',
    [SoftOfferAcceptionQuestionsFields.isDangerousDriverOccur]: false,
    [SoftOfferAcceptionQuestionsFields.dangerousDriverOccurReason]: '',
    [SoftOfferAcceptionQuestionsFields.isAllDriversLicencedToDrive]: true,
    [SoftOfferAcceptionQuestionsFields.isAllDriversResidents]: true,
  } as SoftOfferAcceptionQuestions);

import styled from 'styled-components';
import { Button } from 'components/button';

// clearing opacity for pseudo element
// in order to remove default animcation effect
export const ScButton = styled(Button)`
  && {
    transition: color 0.3s;
    color: ${({ theme }) => theme.colors.dustyGray};
    &.active {
      color: ${({ theme }) => theme.colors.vampire};
    }
    font-weight: 500;
    line-height: 0.836em;
    border: none;
    padding: 0 0 0.5em 0;
    height: auto;
    margin: 0 0.938em;

    &:disabled {
      background: none;
      &:hover,
      &:focus,
      &:active {
        background: none;
      }
    }

    position: relative;
    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      transition: border-color 0.3s;
      border-color: transparent;
      border-width: 0 0 1px 0;
      border-style: solid;
      display: block;
      z-index: 1;
    }
    &.active:before {
      border-color: ${({ theme }) => theme.mainColor};
    }
  }

  &::after {
    display: none;
  }
`;

import {
  FilterField,
  CustomerTypeListItemFields,
  DefaultParamsListItemFields,
  CustomerTypeListItem,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerFields, CustomerItem } from 'types/vendor';
import { Col, Row } from 'antd';
import { Button } from 'components/button';
import { useContact } from 'context/contact/ContactProvider';
import { useCountry } from 'context/country/CountryProvider';
import { getCustomerInitialValues, useCustomer } from 'context/customer/CustomerProvider';
import { useLanguage } from 'context/language/LanguageProvider';
import { FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { get, isNil, uniqBy } from 'utils';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  PropsWithChildren,
  FormEvent,
  useState,
} from 'react';
import { DEFAULT_COUNTRY_CODE } from 'utils/constants';
import { UserForm, UserType } from '../customerForms/UserForm';
import { notification, NotificationType } from 'utils/notification';
import { Status } from 'utils/types';
import { getCustomerFormSchema } from './customer-form-schema';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useFeature } from 'context/feature/FeatureProvider';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from 'store';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';

interface Props {
  onSubmit?(values?: CustomerItem | null): void;
  onWarningClick?(name: CustomerFields, value: string): void;
  reloadCustomers?(
    filters: FilterField<CustomerFields>[],
  ): Promise<CustomerItem[] | null>;
  t: TFunction;
}
export const CreateCustomerForm: FC<PropsWithChildren<Props>> = ({
  children,
  onSubmit,
  onWarningClick,
  reloadCustomers,
  t,
}) => {
  const { languages } = useLanguage();
  const { countries } = useCountry();
  const { contacts, getContactsList } = useContact();
  const { getCustomers, loadCustomersList, customerTypes, createCustomer } =
    useCustomer();
  const { isFeatureEnabled } = useFeature();

  const isCustomerTypeEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerTypeEnabled,
  });
  const isCommercialTypeEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CommercialTypeEnabled,
  });
  const isCommercialTypeEditable = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CommercialTypeEditable,
  });
  const isCommercialTypeRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CommercialTypeRequired,
  });
  const isCustomerAddressFieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerAddressFieldRequired,
  });
  const isCustomerPhoneFieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerPhoneFieldRequired,
  });
  const isCustomerPhone2FieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerPhone2FieldRequired,
  });
  const isCustomerMobilePhoneFieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerMobilePhoneFieldRequired,
  });
  const isCustomerMobilePhone2FieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerMobilePhone2FieldRequired,
  });

  const defaultValues = useSelector(featuresFlagsSelectors.getDefaultValues);

  const defaultCustomerType = useMemo<CustomerTypeListItem | undefined>(() => {
    const code = defaultValues?.[DefaultParamsListItemFields.defaultCustomerType];
    if (isNil(code)) return;
    return customerTypes.find(type => type[CustomerTypeListItemFields.code] === code);
  }, [customerTypes, defaultValues]);

  const formSchema = useMemo(
    () =>
      getCustomerFormSchema(t, {
        isCommercialTypeRequired: isCommercialTypeRequired,
        isCustomerTypeEnabled: isCustomerTypeEnabled,
        isCommercialTypeEnabled: isCommercialTypeEnabled,
        isCustomerAddressFieldRequired: isCustomerAddressFieldRequired,
        isCustomerPhoneFieldRequired: isCustomerPhoneFieldRequired,
        isCustomerPhone2FieldRequired: isCustomerPhone2FieldRequired,
        isCustomerMobilePhoneFieldRequired: isCustomerMobilePhoneFieldRequired,
        isCustomerMobilePhone2FieldRequired: isCustomerMobilePhone2FieldRequired,
      }),
    [
      isCommercialTypeEnabled,
      isCommercialTypeRequired,
      isCustomerAddressFieldRequired,
      isCustomerMobilePhone2FieldRequired,
      isCustomerMobilePhoneFieldRequired,
      isCustomerPhone2FieldRequired,
      isCustomerPhoneFieldRequired,
      isCustomerTypeEnabled,
      t,
    ],
  );
  const [initialValues, setInitialValues] = useState<CustomerItem>();
  useEffect(() => {
    const customerTypeCode = get(
      defaultCustomerType,
      CustomerTypeListItemFields.code,
      '',
    );
    setInitialValues({
      ...getCustomerInitialValues(),
      [CustomerFields.type]: customerTypeCode,
      [CustomerFields.country]: DEFAULT_COUNTRY_CODE,
    });
  }, [defaultCustomerType]);

  const handleSubmit = useCallback(
    async (values: CustomerItem, actions: FormikHelpers<CustomerItem>) => {
      actions.setSubmitting(true);
      const { status, messageHandled, response } = await createCustomer(values);
      await getCustomers();

      actions.setSubmitting(false);
      actions.resetForm();

      if (typeof onSubmit === 'function' && status === Status.Success) {
        onSubmit(response);
      }

      if (status === Status.Success && !messageHandled) {
        notification.open({
          message: t('CLIENTS_NEW_CLIENT_CREATED'),
          type: NotificationType.success,
        });
      }

      if (status === Status.Error && !messageHandled) {
        notification.open({
          message: t('CLIENTS_NEW_CLIENT_CREATION_ERROR'),
          type: NotificationType.error,
        });
      }
    },
    [getCustomers, onSubmit, createCustomer, t],
  );

  const [prefillOptionsData, setPrefillOptionsData] = useState<CustomerItem[]>([]);
  const onPrefillOptionSelect = useCallback(
    (id: string) => {
      const record = prefillOptionsData.find(
        item => String(item[CustomerFields.vatNumber]) === id,
      );
      if (!record) return;
      setInitialValues(record);
    },
    [prefillOptionsData],
  );
  const handleChange = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      const value = (event.target as HTMLInputElement).value;
      // Antd Autocomplete somewhy doesnt doesnt have way to pass 'name'
      const name = (event.target as HTMLInputElement).id;

      if (!name) return;
      if (name !== CustomerFields.vatNumber) return;
      const customers = await loadCustomersList([
        { name: CustomerFields.vatNumber, value: value, sign: 'includes' },
      ]);
      setPrefillOptionsData(customers ?? []);
    },
    [loadCustomersList],
  );

  const prefillOptionsList = useMemo(() => {
    return uniqBy(
      mapToSelectOptions(prefillOptionsData, {
        value: CustomerFields.vatNumber,
        label: [CustomerFields.name, CustomerFields.name2],
        separator: ' - ',
      }),
      'value',
    );
  }, [prefillOptionsData]);

  useEffect(() => {
    if (!contacts.length) {
      getContactsList();
    }
  }, [contacts, getContactsList]);

  return (
    <UserForm
      formSchema={formSchema}
      handleWarningIconClick={onWarningClick as any}
      readonly={false}
      languages={languages}
      countries={countries}
      userType={UserType.Customer}
      initialFormValues={initialValues as CustomerItem | null}
      onFormChange={handleChange}
      prefillOptionsList={prefillOptionsList}
      onPrefillOptionSelect={onPrefillOptionSelect}
      onSubmit={handleSubmit}
      reloadEntities={reloadCustomers as any}
      showCommercialCustomerType={isCommercialTypeEnabled}
      disableCommercialCustomerType={!isCommercialTypeEditable}
      footer={
        <>
          <Row gutter={[20, 20]} data-testid="customer-page-new-client-tab-form-buttons">
            <Col span={24}>
              <Button
                fullwidth
                variant="primary"
                htmlType="submit"
                data-testid="customer-page-new-client-tab-submit-btn"
              >
                {t('CLIENTS_SAVE_AND_CONTINUE')}
              </Button>
            </Col>
            <Col span={24}>{children}</Col>
          </Row>
        </>
      }
    />
  );
};

import { SoftOfferSecurityDepositFields, SoftOfferSecurityDeposit } from 'types/vendor';

export const getInitialValues = (): SoftOfferSecurityDeposit =>
  ({
    [SoftOfferSecurityDepositFields.id]: 0,
    [SoftOfferSecurityDepositFields.configurationNumber]: '',
    [SoftOfferSecurityDepositFields.changedAt]: null,
    [SoftOfferSecurityDepositFields.changedBy]: '',

    [SoftOfferSecurityDepositFields.isThirdPartyLiabilityChosen]: true,
    [SoftOfferSecurityDepositFields.vehiclePlaces]: 0,
    [SoftOfferSecurityDepositFields.yearsWithoutSinistre]: 0,
    [SoftOfferSecurityDepositFields.driverLastClaimsFaultAt]: null,
    [SoftOfferSecurityDepositFields.isOmnium]: true,
    [SoftOfferSecurityDepositFields.omniumType]: '',
    [SoftOfferSecurityDepositFields.evaluationType]: '',
    [SoftOfferSecurityDepositFields.invoicePrice]: 0,
    [SoftOfferSecurityDepositFields.vehicleValueNetto]: 0,
    [SoftOfferSecurityDepositFields.vehicleValueBrutto]: 0,
    [SoftOfferSecurityDepositFields.deductibleFormula]: '',
    [SoftOfferSecurityDepositFields.guaranteeFormulaTmp]: '',
    [SoftOfferSecurityDepositFields.guaranteeFormula]: '',
    [SoftOfferSecurityDepositFields.isSafetyPackChosen]: false,
    [SoftOfferSecurityDepositFields.isLegalAssistanceChosen]: false,
    [SoftOfferSecurityDepositFields.selectedPJ]: '',
    [SoftOfferSecurityDepositFields.isDriverChosen]: false,
    [SoftOfferSecurityDepositFields.driver]: '',
    [SoftOfferSecurityDepositFields.vehicleGroupTypeCode]: '',
    [SoftOfferSecurityDepositFields.claimsNotAtFaultCount]: 0,
    [SoftOfferSecurityDepositFields.claimsVehicleDamageCount]: 0,
    [SoftOfferSecurityDepositFields.theftClaimsCount]: 0,
  } as SoftOfferSecurityDeposit);

import React, { FC } from 'react';
import { FinancingTable } from './Table';
import { FinancingForm } from './Form';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FINANCING_URL } from 'common/constants';
import { useSelector } from 'react-redux';
import { sharedSelectors } from 'store';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';

export const FinancingPage: FC = () => {
  /** Have it here to prevent /create page being initially considered as /:id page */
  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);
  const { isFeatureEnabled } = useFeature();

  const isAllowAddNew =
    isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.allowFinancingAddNew,
    }) || true;

  return (
    <Switch>
      <Route path={FINANCING_URL + '/list'} component={FinancingTable} />
      {(isAllowAddNew || isWholePageLoading) && (
        <Route
          path={FINANCING_URL + '/create'}
          render={() => <FinancingForm isCreate />}
        />
      )}
      <Route path={FINANCING_URL + '/:id'} component={FinancingForm} />
      <Redirect to={FINANCING_URL + '/list'} />
    </Switch>
  );
};

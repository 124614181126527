import React, { FC, useCallback, useState } from 'react';
import { DocumentQuoteModal, DocumentQuoteModalProps } from '../DocumentQuoteModal';
import {
  ScDocumentsFooterWrapper,
  ScDownloadPdfButton,
  ScPrevStepButton,
  ScSendQuoteButton,
} from './DocumentsFooter.styles';
import { notification } from 'utils/notification';
import { ReactComponent as PDF } from 'assets/icons/pdf.svg';
import { MailtoUrls } from '../utils';
import { copyToClipboardAsHtml } from 'utils/copyToClipboard';
import { Status } from 'utils/types';
import { DocumentsTreeData } from 'hooks/useDocumentsTree';
import { useTranslation } from 'react-i18next';

interface Props {
  documentsSchema: DocumentsTreeData;
  handleGoToPrevStep(): void;
  sendQuoteEmailEnabled: boolean;
  sendQuoteEmailVisible: boolean;
  sendQuoteCustomEmailUrls?: MailtoUrls;
  handleDownloadPdfDocuments(): void;
  downloadPdfDocumentsEnabled: boolean;
  downloadPdfDocumentsVisible: boolean;
}

const DocumentsFooter: FC<Props> = ({
  documentsSchema,
  handleGoToPrevStep,
  sendQuoteEmailEnabled,
  sendQuoteEmailVisible,
  sendQuoteCustomEmailUrls,
  handleDownloadPdfDocuments,
  downloadPdfDocumentsEnabled,
  downloadPdfDocumentsVisible,
}) => {
  const { t } = useTranslation();

  const [sendEmailState, setSendEmailState] = useState<DocumentQuoteModalProps | null>(
    null,
  );

  const handleSetSendEmailState = useCallback(() => {
    setSendEmailState({
      documentsSchema: documentsSchema,
      onCancel: () => setSendEmailState(null),
    });
  }, [setSendEmailState, documentsSchema]);

  const handleSendQuote = useCallback(() => {
    if (!sendQuoteCustomEmailUrls) return;

    const status = copyToClipboardAsHtml(sendQuoteCustomEmailUrls.body);

    notification.openByStatus(status, {
      [Status.Success]: t('DOCUMENTS_CUSTOM_EMAIL_BUTTON__HTML_COPIED__SUCCESS'),
      [Status.Error]: t('DOCUMENTS_CUSTOM_EMAIL_BUTTON__HTML_COPIED__ERROR'),
    });
  }, [sendQuoteCustomEmailUrls, t]);

  return (
    <ScDocumentsFooterWrapper>
      <ScPrevStepButton variant="prev" onClick={handleGoToPrevStep}>
        {t('DOCUMENT_BACK')}
      </ScPrevStepButton>
      {sendEmailState && <DocumentQuoteModal {...sendEmailState} />}
      {downloadPdfDocumentsVisible && (
        <ScDownloadPdfButton
          onClick={handleDownloadPdfDocuments}
          disabled={!downloadPdfDocumentsEnabled}
          icon={<PDF />}
        >
          {t('DOCUMENT_GET_PDF')}
        </ScDownloadPdfButton>
      )}
      {sendQuoteEmailVisible && (
        <ScSendQuoteButton
          variant="highlighted"
          onClick={handleSetSendEmailState}
          disabled={!sendQuoteEmailEnabled}
        >
          {t('DOCUMENT_SEND_QUOTE')}
        </ScSendQuoteButton>
      )}
      {sendQuoteCustomEmailUrls && (
        <ScSendQuoteButton
          variant="highlighted"
          href={sendQuoteCustomEmailUrls.urlNoBody}
          onClick={handleSendQuote}
        >
          {t('DOCUMENTS_CUSTOM_EMAIL_BUTTON')}
        </ScSendQuoteButton>
      )}
    </ScDocumentsFooterWrapper>
  );
};

export default DocumentsFooter;

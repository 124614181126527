import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CountryFields,
  Country,
  CustomerCommercialTypeFields,
  CustomerSalutationFields,
  CustomerTitleFields,
  CustomerTypeFields,
  FilterField,
  LanguageFields,
  Language,
} from 'types/vendor';
import { CustomerFields, CustomerItem } from 'types/vendor';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useFeature } from 'context/feature/FeatureProvider';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Input } from '../form/Input';
import FormField from '../form/formik/FormField';
import { Status } from 'utils/types';
import { getCustomerFormSchema } from './customer-form-schema';
import { useTranslation } from 'react-i18next';
import { useCustomer } from 'context/customer/CustomerProvider';
import { get, uniqBy } from 'utils';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import { RotatingLoader } from '../RotatingLoader';
import { ScCheckOutlined, ScWarningOutlined } from '../customerForms/UserForm.styles';
import { FILTER_SIGNS } from 'common/constants';
import { notification, NotificationType } from 'utils/notification';
import { Col, Row } from 'antd';
import { useLanguage } from 'context/language/LanguageProvider';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';
import { useCountry } from 'context/country/CountryProvider';
import Select from 'components/form/Selector';
import { MobileInput, MobileInputCountry } from '../form/MobileInput';
import { Button } from '../button';
import { isFieldRequired } from 'utils/is-field-required';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { InputCityZipField } from 'components/form/formik/InputCityZipField';

const ROW_SPACES: [number, number] = [20, 0];
const SUB_ROW_SPACES: [number, number] = [16, 0];

interface Props {
  initialValues?: CustomerItem | null;
  onSubmit?: (
    values: CustomerItem,
    actions: FormikHelpers<CustomerItem>,
  ) => Promise<void>;
  header?: ReactNode;
  readonly?: boolean;
  handleWarningIconClick?(name: CustomerFields, value: string): void;
  reloadEntities?(filters: FilterField<CustomerFields>[]): Promise<CustomerItem[] | null>;
  plainIdx?: number;
}

const CustomerForm: FC<Props> = ({
  header,
  initialValues,
  onSubmit,
  readonly,
  handleWarningIconClick,
  reloadEntities,
  plainIdx = 1,
}) => {
  const { t } = useTranslation();

  const {
    status: customerStatus,
    customerTypes,
    customerCommercialTypes,
    customerSalutations,
    customerTitles,
    getCustomerCommercialTypes,
    getCustomerSalutations,
    getCustomerTitles,
  } = useCustomer();
  const { status: langStatus, languages } = useLanguage();
  const { status: countriesStatus, countries } = useCountry();

  const { isFeatureEnabled } = useFeature();

  const { isConfigurationComplete } = useSelector(modelSelectors.getVariables);

  const isCustomerTypeEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerTypeEnabled,
  });
  const isCommercialTypeEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CommercialTypeEnabled,
  });
  const isCommercialTypeEditable = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CommercialTypeEditable,
  });
  const isCustomerTitleEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerTitleEnabled,
  });
  const isCustomerSalutationEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerSalutationEnabled,
  });
  const isCustomerVatEditEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CustomerVatEditEnabled,
  });
  const isCommercialTypeRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.CommercialTypeRequired,
  });
  const isCustomerAddressFieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerAddressFieldRequired.replace(
      /\d+/,
      String(plainIdx),
    ),
  });
  const isCustomerPhoneFieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerPhoneFieldRequired.replace(
      /\d+/,
      String(plainIdx),
    ),
  });
  const isCustomerPhone2FieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerPhone2FieldRequired.replace(
      /\d+/,
      String(plainIdx),
    ),
  });
  const isCustomerMobilePhoneFieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerMobilePhoneFieldRequired.replace(
      /\d+/,
      String(plainIdx),
    ),
  });
  const isCustomerMobilePhone2FieldRequired = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.Relation1CustomerMobilePhone2FieldRequired.replace(
      /\d+/,
      String(plainIdx),
    ),
  });

  const customerCountryCode: string =
    initialValues?.[CustomerFields.country] || DEFAULT_COUNTRY_CODE;
  const isNewUser = Boolean(initialValues && !initialValues[CustomerFields.id]);

  const [prevVatValue, setPrevVatValue] = useState<string>('');
  const [isLoadingSearchCustomers, setIsLoadingSearchCustomers] =
    useState<boolean>(false);
  const [hasDuplicateSearchCustomers, setHasDuplicateSearchCustomers] = useState<
    boolean | null
  >(null);

  const [defaultCountryCode, setDefaultCountryCode] =
    useState<string>(customerCountryCode);

  useEffect(() => {
    if (!readonly) {
      (async () => {
        await getCustomerCommercialTypes();
        await getCustomerSalutations();
        await getCustomerTitles();
      })();
    }
  }, [getCustomerCommercialTypes, getCustomerSalutations, getCustomerTitles, readonly]);

  const handleSubmit = useCallback(
    async (values: CustomerItem, actions: FormikHelpers<CustomerItem>) => {
      actions.setSubmitting(true);
      if (onSubmit) await onSubmit(values, actions);
      actions.setSubmitting(false);
    },
    [onSubmit],
  );

  const formSchema = useMemo(
    () =>
      getCustomerFormSchema(t, {
        isCommercialTypeRequired: isCommercialTypeRequired,
        isCustomerTypeEnabled: isCustomerTypeEnabled,
        isCommercialTypeEnabled: isCommercialTypeEnabled,
        isCustomerAddressFieldRequired: isCustomerAddressFieldRequired,
        isCustomerPhoneFieldRequired: isCustomerPhoneFieldRequired,
        isCustomerPhone2FieldRequired: isCustomerPhone2FieldRequired,
        isCustomerMobilePhoneFieldRequired: isCustomerMobilePhoneFieldRequired,
        isCustomerMobilePhone2FieldRequired: isCustomerMobilePhone2FieldRequired,
      }),
    [
      t,
      isCommercialTypeRequired,
      isCustomerTypeEnabled,
      isCommercialTypeEnabled,
      isCustomerAddressFieldRequired,
      isCustomerPhoneFieldRequired,
      isCustomerPhone2FieldRequired,
      isCustomerMobilePhoneFieldRequired,
      isCustomerMobilePhone2FieldRequired,
    ],
  );

  const getSuffix = useCallback(
    (params: {
      isLoading: boolean;
      hasDuplicate: boolean | null;
      name: CustomerFields;
      value: string;
    }) => {
      const { isLoading, hasDuplicate, name, value } = params;

      if (isLoading) {
        return <RotatingLoader />;
      }

      if (typeof hasDuplicate === 'boolean') {
        return hasDuplicate ? (
          <ScWarningOutlined
            onClick={() => handleWarningIconClick && handleWarningIconClick(name, value)}
          />
        ) : (
          <ScCheckOutlined />
        );
      }
    },
    [handleWarningIconClick],
  );

  const customerTypeValue = useMemo<string>(() => {
    const customerType = get(initialValues, CustomerFields.type);
    return get(
      customerTypes.find(type => get(type, CustomerFields.type) === customerType),
      CustomerTypeFields.description,
      '',
    );
  }, [initialValues, customerTypes]);

  const handleLoadCustomersList = useCallback(
    (value: string) => {
      setIsLoadingSearchCustomers(true);
      const filters = [
        {
          name: CustomerFields.vatNumber,
          value,
          sign: FILTER_SIGNS.EQUAL,
        },
      ];

      if (!reloadEntities) return;

      reloadEntities(filters).then(customers => {
        setIsLoadingSearchCustomers(false);
        const hasDuplicate = Boolean(customers && customers.length);
        setHasDuplicateSearchCustomers(hasDuplicate);
        notification.open({
          header: t(`DUPLICATE_${hasDuplicate ? '' : 'NOT_'}FOUND_TITLE`, {
            entity: 'customer',
          }),
          message: t(`DUPLICATE_${hasDuplicate ? '' : 'NOT_'}FOUND_CONTENT`, {
            entity: 'customer',
          }),
          type: hasDuplicate ? NotificationType.warning : NotificationType.success,
        });

        return customers;
      });
    },
    [t, setIsLoadingSearchCustomers, setHasDuplicateSearchCustomers, reloadEntities],
  );

  const commercialTypeOptions = useMemo(() => {
    const list = uniqBy(customerCommercialTypes, CustomerCommercialTypeFields.code);
    return mapToSelectOptions(list, {
      label: CustomerCommercialTypeFields.name,
      value: CustomerCommercialTypeFields.code,
    });
  }, [customerCommercialTypes]);

  const titleOptions = useMemo(() => {
    const list = uniqBy(customerTitles, CustomerTitleFields.name);
    return mapToSelectOptions(list, {
      label: CustomerTitleFields.name,
      value: CustomerTitleFields.code,
    });
  }, [customerTitles]);

  const salutationOptions = useMemo(() => {
    const list = uniqBy(customerSalutations, CustomerSalutationFields.name);
    return mapToSelectOptions(list, {
      label: CustomerSalutationFields.name,
      value: CustomerSalutationFields.code,
    });
  }, [customerSalutations]);

  const languageOptions = useMemo(() => {
    return mapToSelectOptions<Language>(languages, {
      label: LanguageFields.language,
      value: LanguageFields.languageCode,
    });
  }, [languages]);

  const countryOptions = useMemo(() => {
    return mapToSelectOptions<Country>(countries, {
      label: CountryFields.country,
      value: CountryFields.countryCode,
    });
  }, [countries]);

  const countriesPhoneCodeList = useMemo<MobileInputCountry[]>(
    () =>
      countries.map(country => country[CountryFields.countryCode] as MobileInputCountry),
    [countries],
  );

  // this field presence is result of "Issue 1" for Formik
  // after dep update should remove this field and use ternary in parent components for render
  const hideAllFields = !initialValues;

  return (
    <Formik
      initialValues={initialValues ?? {}}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={formSchema}
    >
      {(formProps: FormikProps<CustomerItem>) => {
        if (hideAllFields) return header;

        return (
          <form
            onSubmit={e => {
              e.stopPropagation();
              formProps.handleSubmit(e);
            }}
            noValidate
            data-testid="customer-page-form"
          >
            <Row gutter={ROW_SPACES}>
              <Col md={8}>
                {header}

                {isCustomerTypeEnabled && (
                  <FormField
                    name={CustomerFields.type}
                    component={Select}
                    loading={customerStatus === Status.Loading}
                    value={customerTypeValue}
                    options={[]}
                    label={t('TYPE')}
                    required={isFieldRequired(formSchema, CustomerFields.type)}
                    data-testid="customer-page-form-user-type-field"
                    disabled
                  />
                )}

                {isCommercialTypeEnabled && (
                  <FormField
                    disabled={readonly || !isCommercialTypeEditable}
                    name={CustomerFields.commercialType}
                    id={CustomerFields.commercialType}
                    component={Select}
                    label={t('COMMERCIAL_TYPE')}
                    required={isFieldRequired(formSchema, CustomerFields.commercialType)}
                    placeholder={t('COMMERCIAL_TYPE')}
                    data-testid="customer-page-form-commercial-type-field"
                    options={commercialTypeOptions}
                    showSearch
                  />
                )}

                {initialValues && (
                  <FormField
                    disabled={readonly || !isCustomerVatEditEnabled}
                    name={CustomerFields.vatNumber}
                    required={isFieldRequired(formSchema, CustomerFields.vatNumber)}
                    id={CustomerFields.vatNumber}
                    suffix={
                      formProps.values[CustomerFields.vatNumber] &&
                      getSuffix({
                        isLoading: isLoadingSearchCustomers,
                        hasDuplicate: hasDuplicateSearchCustomers,
                        name: CustomerFields.vatNumber,
                        value: formProps.values[CustomerFields.vatNumber] ?? '',
                      })
                    }
                    component={Input}
                    label={t('VAT')}
                    data-testid="customer-page-form-mobile-vatCode-field"
                    onBlur={event => {
                      const value = event.target.value.trim();
                      formProps.setFieldValue(CustomerFields.vatNumber, value);
                      value && prevVatValue !== value && handleLoadCustomersList(value);
                      setPrevVatValue(value);
                    }}
                    onChange={event => {
                      const value = event.target.value.trim();
                      setPrevVatValue(formProps.values[CustomerFields.vatNumber] ?? '');
                      formProps.setFieldValue(CustomerFields.vatNumber, value);
                    }}
                  />
                )}

                {(isCustomerTitleEnabled || isCustomerSalutationEnabled) && (
                  <Row gutter={SUB_ROW_SPACES}>
                    {isCustomerTitleEnabled && (
                      <Col md={12}>
                        <FormField
                          disabled={readonly}
                          name={CustomerFields.title}
                          id={CustomerFields.title}
                          required={isFieldRequired(formSchema, CustomerFields.title)}
                          component={Select}
                          label={t('CLIENTS_TITLE')}
                          placeholder={t('CLIENTS_TITLE')}
                          data-testid="customer-page-form-title-field"
                          options={titleOptions}
                        />
                      </Col>
                    )}

                    {isCustomerSalutationEnabled && (
                      <Col md={12}>
                        <FormField
                          disabled={readonly}
                          name={CustomerFields.salutation}
                          required={isFieldRequired(
                            formSchema,
                            CustomerFields.salutation,
                          )}
                          id={CustomerFields.salutation}
                          component={Select}
                          label={t('CLIENTS_SALUTATION')}
                          placeholder={t('CLIENTS_SALUTATION')}
                          data-testid="customer-page-form-salutation-field"
                          options={salutationOptions}
                        />
                      </Col>
                    )}
                  </Row>
                )}

                {/* Ugly fix to make form look better */}
                {!isCustomerTitleEnabled && !isCustomerSalutationEnabled && (
                  <FormField
                    disabled={readonly}
                    name={CustomerFields.address}
                    id={CustomerFields.address}
                    component={Input}
                    label={t('CLIENTS_ADDRESS')}
                    required={isFieldRequired(formSchema, CustomerFields.address)}
                    data-testid="customer-page-form-address-field"
                  />
                )}
              </Col>

              <Col md={8}>
                <FormField
                  disabled={readonly}
                  id={CustomerFields.name}
                  name={CustomerFields.name}
                  required={isFieldRequired(formSchema, CustomerFields.name)}
                  component={Input}
                  label={t('CLIENTS_FIRST_NAME')}
                  data-testid="customer-page-form-name-field"
                />

                <FormField
                  disabled={readonly}
                  id={CustomerFields.name2}
                  name={CustomerFields.name2}
                  required={isFieldRequired(formSchema, CustomerFields.name2)}
                  component={Input}
                  label={t('CLIENTS_LAST_NAME')}
                  data-testid="customer-page-form-name2-field"
                />

                <InputCityZipField
                  inputZipProps={{
                    id: CustomerFields.zip,
                    name: CustomerFields.zip,
                    required: isFieldRequired(formSchema, CustomerFields.zip),
                    disabled: readonly,
                    label: t('CLIENTS_ZIP'),
                    ['data-testid']: 'customer-page-form-zipcode-field',
                  }}
                  inputCityProps={{
                    id: CustomerFields.city,
                    name: CustomerFields.city,
                    required: isFieldRequired(formSchema, CustomerFields.city),
                    disabled: readonly,
                    label: t('CLIENTS_CITY'),
                    ['data-testid']: 'customer-page-form-city-field',
                  }}
                />

                {(isCustomerTitleEnabled || isCustomerSalutationEnabled) && (
                  <FormField
                    disabled={readonly}
                    name={CustomerFields.address}
                    id={CustomerFields.address}
                    component={Input}
                    label={t('CLIENTS_ADDRESS')}
                    required={isFieldRequired(formSchema, CustomerFields.address)}
                    data-testid="customer-page-form-address-field"
                  />
                )}

                <FormField
                  disabled={readonly}
                  name={CustomerFields.address2}
                  required={isFieldRequired(formSchema, CustomerFields.address2)}
                  id={CustomerFields.address2}
                  component={Input}
                  label={`${t('CLIENTS_ADDRESS')} 2`}
                  placeholder={''}
                  data-testid="customer-page-form-second-address-field"
                />
              </Col>
              <Col md={8}>
                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      name={CustomerFields.lang}
                      component={Select}
                      loading={langStatus === Status.Loading}
                      options={languageOptions}
                      label={t('CLIENTS_LANGUAGE_CODE')}
                      disabled={readonly}
                      required={isFieldRequired(formSchema, CustomerFields.lang)}
                      data-testid="customer-page-form-user-language-field"
                    />
                  </Col>
                  <Col md={12}>
                    <FormField
                      name={CustomerFields.country}
                      component={Select}
                      loading={countriesStatus === Status.Loading}
                      options={countryOptions}
                      label={t('CLIENTS_COUNTRY')}
                      required={isFieldRequired(formSchema, CustomerFields.vatNumber)}
                      onChange={event => {
                        formProps.setFieldValue(
                          CustomerFields.country,
                          event.target.value,
                        );
                        if (isNewUser) {
                          formProps.setFieldValue(CustomerFields.phone, '');
                          formProps.setFieldValue(CustomerFields.mobilePhone, '');
                          setDefaultCountryCode(event.target.value);
                        }
                      }}
                      disabled={readonly}
                      data-testid="customer-page-form-country-field"
                      showSearch
                    />
                  </Col>
                </Row>

                <Row gutter={SUB_ROW_SPACES}>
                  <Col span={12}>
                    <FormField
                      disabled={readonly}
                      name={CustomerFields.mobilePhone}
                      required={isFieldRequired(formSchema, CustomerFields.mobilePhone)}
                      id={CustomerFields.mobilePhone}
                      component={MobileInput}
                      label={t('CLIENTS_MOBILE_NUMBER')}
                      data-testid="customer-page-form-mobile-phone-field"
                      countries={countriesPhoneCodeList}
                      international={true}
                      defaultCountry={defaultCountryCode as MobileInputCountry}
                      addInternationalOption={false}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      disabled={readonly}
                      name={CustomerFields.mobilePhone2}
                      required={isFieldRequired(formSchema, CustomerFields.mobilePhone2)}
                      id={CustomerFields.mobilePhone2}
                      component={MobileInput}
                      label={t('CLIENTS_MOBILE2_NUMBER')}
                      data-testid="customer-page-form-mobile-phone2-field"
                      countries={countriesPhoneCodeList}
                      international={true}
                      defaultCountry={defaultCountryCode as MobileInputCountry}
                      addInternationalOption={false}
                    />
                  </Col>
                </Row>

                <Row gutter={SUB_ROW_SPACES}>
                  <Col md={12}>
                    <FormField
                      disabled={readonly}
                      name={CustomerFields.phone}
                      id={CustomerFields.phone}
                      component={MobileInput}
                      label={t('CLIENTS_PHONE_NUMBER')}
                      required={isFieldRequired(formSchema, CustomerFields.phone)}
                      data-testid="customer-page-form-phone-field"
                      countries={countriesPhoneCodeList}
                      international={true}
                      defaultCountry={defaultCountryCode as MobileInputCountry}
                      addInternationalOption={false}
                    />
                  </Col>
                  <Col span={12}>
                    <FormField
                      disabled={readonly}
                      name={CustomerFields.phone2}
                      required={isFieldRequired(formSchema, CustomerFields.phone2)}
                      id={CustomerFields.phone2}
                      component={MobileInput}
                      label={t('CLIENTS_PHONE2_NUMBER')}
                      data-testid="customer-page-form-mobile-phone2-field"
                      countries={countriesPhoneCodeList}
                      international={true}
                      defaultCountry={defaultCountryCode as MobileInputCountry}
                      addInternationalOption={false}
                    />
                  </Col>
                </Row>

                <FormField
                  disabled={readonly}
                  name={CustomerFields.email}
                  id={CustomerFields.email}
                  component={Input}
                  label={t('CLIENTS_EMAIL')}
                  required={isFieldRequired(formSchema, CustomerFields.email)}
                  data-testid="customer-page-form-email-field"
                />

                <FormField
                  disabled={readonly}
                  name={CustomerFields.email2}
                  required={isFieldRequired(formSchema, CustomerFields.email2)}
                  id={CustomerFields.email2}
                  component={Input}
                  label={t('CLIENTS_EMAIL2')}
                  data-testid="customer-page-form-email2-field"
                />
              </Col>

              <Col span={8} style={{ marginLeft: 'auto' }}>
                <Button
                  htmlType="submit"
                  fullwidth
                  variant="primary"
                  disabled={isConfigurationComplete}
                  data-testid="customer-page-form-submit"
                >
                  {t('CONFIRM')}
                </Button>
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};

export default CustomerForm;

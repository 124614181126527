import { SoftOfferUsualDriver, SoftOfferUsualDriverFields } from 'types/vendor';

export const getInitialValues = (
  initials?: Partial<SoftOfferUsualDriver>,
): SoftOfferUsualDriver =>
  ({
    [SoftOfferUsualDriverFields.id]: 0,
    [SoftOfferUsualDriverFields.offerId]: '',
    [SoftOfferUsualDriverFields.customerId]: '',
    [SoftOfferUsualDriverFields.changedAt]: null,
    [SoftOfferUsualDriverFields.changedBy]: '',
    [SoftOfferUsualDriverFields.driverLastName]: '',
    [SoftOfferUsualDriverFields.driverFirstname]: '',
    [SoftOfferUsualDriverFields.driverBirthdayAt]: null,
    [SoftOfferUsualDriverFields.driverSex]: '',
    [SoftOfferUsualDriverFields.driverIsHolder]: true,
    [SoftOfferUsualDriverFields.isOccasionalDriversEnabled]: false,
    [SoftOfferUsualDriverFields.driverStreet]: '',
    [SoftOfferUsualDriverFields.driverNr]: '',
    [SoftOfferUsualDriverFields.driverBox]: '',
    [SoftOfferUsualDriverFields.driverZipCode]: '',
    [SoftOfferUsualDriverFields.driverLocality]: '',
    [SoftOfferUsualDriverFields.driverTel]: '',
    [SoftOfferUsualDriverFields.driverFax]: '',
    [SoftOfferUsualDriverFields.driverGsm]: '',
    [SoftOfferUsualDriverFields.driverLanguage]: '',
    [SoftOfferUsualDriverFields.driverEmail]: '',
    [SoftOfferUsualDriverFields.driverJob]: '',
    [SoftOfferUsualDriverFields.driverAffinity]: '',
    [SoftOfferUsualDriverFields.driverLicenceAt]: null,
    [SoftOfferUsualDriverFields.driverLicenceCategoryCode]: '',
    ...initials,
  } as SoftOfferUsualDriver);

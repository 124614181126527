import React, { useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableColumn } from 'components/table';
import { StockMachine, StockMachineFields } from 'types/vendor';
import { FeatureFlagColumnSettings, OptionWithKey } from 'types';
import Checkbox from 'components/form/Checkbox';
import { useSelector } from 'react-redux';
import { featuresFlagsSelectors } from 'store';
import { formatPrice } from 'utils/format';
import { useMachineApi } from 'context/machine/useMachineApi';

enum ColumnKey {
  // selection checkbox for each row
  SELECTION = 'selection',
  STOCK_LOCATION = StockMachineFields.stockLocation,
  SHORT_NAME = StockMachineFields.shortName,
  MODEL_NUMBER = StockMachineFields.modelNumber,
  MACHINE_NUMBER = StockMachineFields.machineNumber,
  MODEL_NAME = StockMachineFields.modelName,
  BRAND_NAME = StockMachineFields.brandName,
  SOLD_AT = StockMachineFields.soldAt,
  CONSTRUCTED_AT = StockMachineFields.constructedAt,
  COLOR_CODE = StockMachineFields.colorCode,
  COLOR_DESCRIPTION = StockMachineFields.colorDescription,
  INTERIOR_CODE = StockMachineFields.interiorCode,
  INTERIOR_DESCRIPTION = StockMachineFields.interiorDescription,
  FUEL_CODE = StockMachineFields.fuelCode,
  PRICE_WITH_VAT = StockMachineFields.priceWithVat,
  WITH_MARGIN = StockMachineFields.withMargin,
  KM = StockMachineFields.kilometrage,
  WHEEL_BASE = StockMachineFields.wheelBase,
  STOCK_NUMBER = StockMachineFields.stockNumber,
  ORDER_NUMBER = StockMachineFields.orderNumber,
  LOCATION_NUMBER = StockMachineFields.locationNumber,
  LOCATION_NAME = StockMachineFields.locationName,
}

type Params = {
  handleSelect(stockMachineId: string): void;
  selectedStockMachineNumber: string | null;
};

export default function useStockMachineColumns({
  handleSelect,
  selectedStockMachineNumber,
}: Params): TableColumn<StockMachine>[] {
  const { t, i18n } = useTranslation();

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
  const columnsSettings = globalFeatures?.StockMachineColumns;

  const [fuelCodes, setFuelCodes] = useState<OptionWithKey[]>([]);
  const { getFuelOptions } = useMachineApi();

  useEffect(() => {
    const on = true;
    const fn = async () => {
      const list = await getFuelOptions();
      if (on) setFuelCodes(list);
    };
    const handle = setTimeout(fn);
    return () => {
      clearTimeout(handle);
    };
  }, [getFuelOptions]);

  // reason of minWidth defined in {@link Table component minWidth property}
  const keyToColumn: Record<
    string,
    (settings?: FeatureFlagColumnSettings) => ColumnType<StockMachine>
  > = useMemo(() => {
    return {
      [ColumnKey.SELECTION]: () => ({
        title: '',
        dataIndex: ColumnKey.SELECTION,
        key: ColumnKey.SELECTION,
        width: '3em',
        render: (_value, record) => {
          return (
            <Checkbox
              circle
              onChange={() => {
                handleSelect(record[StockMachineFields.machineNumber]);
              }}
              checked={
                record[StockMachineFields.machineNumber] === selectedStockMachineNumber
              }
            />
          );
        },
      }),
      [ColumnKey.STOCK_LOCATION]: () => ({
        title: t('STOCK_MACHINE_COLUMN_LOCATION'),
        dataIndex: ColumnKey.STOCK_LOCATION,
        key: ColumnKey.STOCK_LOCATION,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.SHORT_NAME]: () => ({
        title: t('STOCK_MACHINE_COLUMN_SHORT_NAME'),
        dataIndex: ColumnKey.SHORT_NAME,
        key: ColumnKey.SHORT_NAME,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.MODEL_NAME]: () => ({
        title: t('STOCK_MACHINE_COLUMN_MODEL_NAME'),
        dataIndex: ColumnKey.MODEL_NAME,
        key: ColumnKey.MODEL_NAME,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.BRAND_NAME]: () => ({
        title: t('STOCK_MACHINE_COLUMN_BRAND_NAME'),
        dataIndex: ColumnKey.BRAND_NAME,
        key: ColumnKey.BRAND_NAME,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.INTERIOR_CODE]: () => ({
        title: t('STOCK_MACHINE_COLUMN_INTERIOR_CODE'),
        dataIndex: ColumnKey.INTERIOR_CODE,
        key: ColumnKey.INTERIOR_CODE,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.INTERIOR_DESCRIPTION]: () => ({
        title: t('STOCK_MACHINE_COLUMN_INTERIOR_CODE'),
        dataIndex: ColumnKey.INTERIOR_DESCRIPTION,
        key: ColumnKey.INTERIOR_DESCRIPTION,
        width: '3em',
        maxWidth: '10rem',
        sorter: true,
      }),
      [ColumnKey.FUEL_CODE]: () => ({
        title: t('STOCK_MACHINE_COLUMN_FUEL_CODE'),
        dataIndex: ColumnKey.FUEL_CODE,
        key: ColumnKey.FUEL_CODE,
        width: '3em',
        sorter: true,
        render: value => {
          return fuelCodes.find(record => value === record.value)?.label || value;
        },
      }),
      [ColumnKey.ORDER_NUMBER]: () => ({
        title: t('STOCK_MACHINE_COLUMN_ORDER_NUMBER'),
        dataIndex: ColumnKey.ORDER_NUMBER,
        key: ColumnKey.ORDER_NUMBER,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.WITH_MARGIN]: () => ({
        title: t('STOCK_MACHINE_COLUMN_WITH_MARGIN'),
        dataIndex: ColumnKey.WITH_MARGIN,
        key: ColumnKey.WITH_MARGIN,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.LOCATION_NUMBER]: () => ({
        title: t('STOCK_MACHINE_COLUMN_LOCATION_NUMBER'),
        dataIndex: ColumnKey.LOCATION_NUMBER,
        key: ColumnKey.LOCATION_NUMBER,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.LOCATION_NAME]: () => ({
        title: t('STOCK_MACHINE_COLUMN_LOCATION_NAME'),
        dataIndex: ColumnKey.LOCATION_NAME,
        key: ColumnKey.LOCATION_NAME,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.WHEEL_BASE]: () => ({
        title: t('STOCK_MACHINE_COLUMN_WHEEL_BASE'),
        dataIndex: ColumnKey.WHEEL_BASE,
        key: ColumnKey.WHEEL_BASE,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.STOCK_NUMBER]: () => ({
        title: t('STOCK_MACHINE_COLUMN_STOCK_NUMBER'),
        dataIndex: ColumnKey.STOCK_NUMBER,
        key: ColumnKey.STOCK_NUMBER,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.KM]: () => ({
        title: t('STOCK_MACHINE_COLUMN_KM'),
        dataIndex: ColumnKey.KM,
        key: ColumnKey.KM,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.CONSTRUCTED_AT]: () => ({
        title: t('STOCK_MACHINE_COLUMN_CONSTRUCTED_AT'),
        dataIndex: ColumnKey.CONSTRUCTED_AT,
        key: ColumnKey.CONSTRUCTED_AT,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.COLOR_CODE]: () => ({
        title: t('STOCK_MACHINE_COLUMN_COLOR_CODE'),
        dataIndex: ColumnKey.COLOR_CODE,
        key: ColumnKey.COLOR_CODE,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.COLOR_DESCRIPTION]: () => ({
        title: t('STOCK_MACHINE_COLUMN_COLOR_CODE'),
        dataIndex: ColumnKey.COLOR_DESCRIPTION,
        key: ColumnKey.COLOR_DESCRIPTION,
        width: '3em',
        maxWidth: '10rem',
        sorter: true,
      }),
      [ColumnKey.PRICE_WITH_VAT]: () => ({
        title: t('STOCK_MACHINE_COLUMN_PRICE_WITH_VAT'),
        dataIndex: ColumnKey.PRICE_WITH_VAT,
        key: ColumnKey.PRICE_WITH_VAT,
        width: '3em',
        sorter: true,
        render: value => {
          return formatPrice({ price: value, locale: i18n.language, style: 'decimal' });
        },
      }),
      [ColumnKey.MACHINE_NUMBER]: () => ({
        title: t('STOCK_MACHINE_COLUMN_MACHINE_NUMBER'),
        dataIndex: ColumnKey.MACHINE_NUMBER,
        key: ColumnKey.MACHINE_NUMBER,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.SOLD_AT]: () => ({
        title: t('STOCK_MACHINE_COLUMN_SOLD_AT'),
        dataIndex: ColumnKey.SOLD_AT,
        key: ColumnKey.SOLD_AT,
        width: '3em',
        sorter: true,
      }),
      [ColumnKey.MODEL_NUMBER]: () => ({
        title: t('STOCK_MACHINE_COLUMN_MODEL_NUMBER'),
        dataIndex: ColumnKey.MODEL_NUMBER,
        key: ColumnKey.MODEL_NUMBER,
        width: '3em',
        sorter: true,
      }),
    };
  }, [handleSelect, i18n.language, selectedStockMachineNumber, t]);

  const columns = useMemo<ColumnType<StockMachine>[]>(() => {
    if (!columnsSettings?.national?.length) return [];
    return columnsSettings.national.reduce<ColumnType<StockMachine>[]>((acc, setting) => {
      const column = keyToColumn[setting.name]?.();
      if (column) acc.push(column);
      return acc;
    }, []);
  }, [columnsSettings, keyToColumn]);

  return columns;
}

import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FileZip } from 'utils/file-zip';
import { useTranslation } from 'react-i18next';
import Masonry from 'components/Masonry';
import { useFeature } from 'context/feature/FeatureProvider';
import { useStep } from 'context/step/StepProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import Layout from 'layout/Default/Layout';
import { sizes } from 'common/theme';
import { useDocumentTemplateApi } from 'context/documentTemplate/useDocumentTemplateApi';
import {
  PrintTemplateListItemFields,
  DocumentTypeListItem,
  PrintTemplateListItem,
} from '@hypercharge/xdms-client/lib/types';
import { notification, NotificationType } from 'utils/notification';
import { Status } from 'utils/types';
import {
  DOCUMENT_GENERATION_DOCTYPE__DOCUMENTS_PAGE,
  PRIMARY_DYNAMIC_FIELD,
} from 'common/constants';
import { useRelations } from 'context/relations/RelationsProvider';
import { getCustomEmailMailtoUrl, MailtoUrls } from './utils';
import { useSelector } from 'react-redux';
import {
  configurationSelectors,
  documentTemplatesSelectors,
  documentTypesSelectors,
  modelSelectors,
} from 'store';
import { DocumentItem, DocumentRelatedEntityCode } from 'context/document/types';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { DocumentsList } from 'components/document/List/DocumentsList';
import { Button } from 'components/button';
import {
  DocumentTreeDataActionType,
  useDocumentsTree,
  DocumentsTreeData,
  documentTreeHelpers,
} from 'hooks/useDocumentsTree';
import { useDocumentTypesApi } from 'context/document/useDocumentTypesApi';
import { DocumentTemplateDropdown } from 'components/documentTemplate/DocumentTemplateDropdown';
import DocumentEntity from './components/DocumentEntity';
import DocumentsFooter from './components/DocumentsFooter';
import ItemHeader from './components/ItemHeader';
import { ScDocumentsHeader, ScPublishButtonWrapper } from './index.styles';
import { GetDocumentTemplatesEntities } from 'types/vendor';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';

export const Documents: FC = () => {
  const { t } = useTranslation();
  const { iso: currentLanguageISOCOde } = useCurrentLanguageCode();
  const { handlePrevStep } = useStep();
  const { getPDFTemplates } = useDocumentTemplateApi();
  const { isFeatureEnabled } = useFeature();
  const { relations } = useRelations();

  const { getDocumentsForAllEntities, generateConfigurationDocument } = useDocumentsApi();
  const { getDocumentTypes } = useDocumentTypesApi();

  const { model, isConfigurationComplete } = useSelector(modelSelectors.getAll);
  const { configurationModelName } = useSelector(configurationSelectors.getAll);
  const documentTypesList = useSelector(documentTypesSelectors.getTypesList);
  const pdfTemplates = useSelector(documentTemplatesSelectors.getTemplatesList);

  const { documentsTreeState, documentsTreeDispatch } = useDocumentsTree();

  const isAllowDocumentsConfigurationsPublish = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowDocumentsConfigurationsPublish,
  });
  const isAllowUploadLink = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowDocumentsPageUploadLink,
  });
  const isAllowUploadFile = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowDocumentsPageUploadFile,
  });
  const isAllowDocumentsPageGetPDFButton = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowDocumentsPageGetPDFButton,
  });
  const isAllowDocumentsPageEmailButton = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowDocumentsPageEmailButton,
  });
  const isAllowDocumentsPageCustomEmailButton = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowDocumentsPageCustomEmailButton,
  });
  const isContactsEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.ContactsEnabled,
  });
  const shouldSkipNoMail = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.SkipNoMail,
  });

  const documentType = useMemo<DocumentTypeListItem | undefined>(() => {
    return documentTypesList.find(
      type => type.id === DOCUMENT_GENERATION_DOCTYPE__DOCUMENTS_PAGE,
    );
  }, [documentTypesList]);

  useEffect(() => {
    getDocumentTypes();
  }, [getDocumentTypes]);

  useEffect(() => {
    getPDFTemplates({
      entity: GetDocumentTemplatesEntities.configuration,
    });
  }, [getPDFTemplates]);

  const reloadDocuments = useCallback(() => {
    getDocumentsForAllEntities().then(({ response }) => {
      if (!response) return;
      documentsTreeDispatch({
        type: DocumentTreeDataActionType.initFromSwarm,
        documentsSwarm: response,
        model: model ?? undefined,
      });
    });
  }, [getDocumentsForAllEntities, documentsTreeDispatch, model]);

  useEffect(() => {
    reloadDocuments();
  }, [reloadDocuments]);

  const selectedDocumentFiles = useMemo<DocumentItem[]>(() => {
    const res: DocumentItem[] = [];

    documentsTreeState.forEach(entity => {
      entity.relatedEntities.forEach(entityItem => {
        const selectedDocuments = entityItem.documents.filter(
          ({ id, computed: { isFile } }) => {
            return entityItem.selectedIds.includes(id) && isFile;
          },
        );
        res.push(...selectedDocuments);
      });
    });

    return res;
  }, [documentsTreeState]);

  const handleDownloadPdfDocuments = useCallback(async () => {
    const configurationName = `${t('DOCUMENT_OFFER')}-${configurationModelName}`;

    const zip = new FileZip(configurationName);
    const folder = zip.createFolder(configurationName);

    const files = selectedDocumentFiles.map(({ fileUrl, fileName, name }) => ({
      fileUrl,
      fileName: name ?? fileName,
    }));
    folder && (await zip.putFilesToFolder(files, folder));

    zip.download();
  }, [t, configurationModelName, selectedDocumentFiles]);

  const onPublish = useCallback(
    async (printTemplate: PrintTemplateListItem) => {
      if (!documentType) {
        notification.open({
          message: t('DOCUMENT_GENERATE_FILE_ERROR'),
          type: NotificationType.error,
        });
        return;
      }

      const { messageHandled, status } = await generateConfigurationDocument(
        printTemplate,
        documentType,
      );

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('DOCUMENT_GENERATE_FILE_SUCCESS'),
          [Status.Error]: t('DOCUMENT_GENERATE_FILE_ERROR'),
        });
      }

      reloadDocuments();
    },
    [documentType, generateConfigurationDocument, reloadDocuments, t],
  );

  const filteredPdfTemplates = useMemo(() => {
    return pdfTemplates.filter(
      pdfTemplate =>
        pdfTemplate[PrintTemplateListItemFields.dynamicField] !== PRIMARY_DYNAMIC_FIELD,
    );
  }, [pdfTemplates]);

  const sendQuoteCustomEmailUrls = useMemo<MailtoUrls | undefined>(() => {
    if (!isAllowDocumentsPageCustomEmailButton) return;

    const documentsTree = documentTreeHelpers.filterSelectedDocuments(documentsTreeState);

    return getCustomEmailMailtoUrl(
      relations,
      isContactsEnabled,
      shouldSkipNoMail,
      configurationModelName ?? null,
      documentsTree,
      t,
      currentLanguageISOCOde,
    );
  }, [
    isAllowDocumentsPageCustomEmailButton,
    documentsTreeState,
    relations,
    isContactsEnabled,
    shouldSkipNoMail,
    configurationModelName,
    t,
    currentLanguageISOCOde,
  ]);

  const getDocumentPlainItem = useCallback(
    (entity: DocumentsTreeData[0]) => (
      <DocumentEntity key={entity.relatedEntityCode} title={t(entity.tKey)}>
        {entity.relatedEntities.map(entityItem => (
          <Fragment key={entityItem.relatedEntity?.[entity.idField]}>
            <ItemHeader
              checkCount={entityItem.selectedIds.length}
              onCheck={() => {
                documentsTreeDispatch({
                  type: DocumentTreeDataActionType.selectGroup,
                  relatedEntityCode: entity.relatedEntityCode,
                  relatedEntity: entityItem.relatedEntity,
                });
              }}
              isOpen={entityItem.isOpen}
              onOpen={() => {
                documentsTreeDispatch({
                  type: DocumentTreeDataActionType.toggleOpenGroup,
                  relatedEntityCode: entity.relatedEntityCode,
                  relatedEntity: entityItem.relatedEntity,
                });
              }}
              name={entityItem.relatedEntity?.[entity.nameField]}
            />
            {entityItem.isOpen && (
              <DocumentsList
                isCaretVisible
                isCheckVisible
                documents={entityItem.documents}
                relatedEntity={entityItem.relatedEntity}
                relatedEntityCode={entity.relatedEntityCode}
                onCheck={document => {
                  documentsTreeDispatch({
                    type: DocumentTreeDataActionType.selectDocument,
                    relatedEntityCode: entity.relatedEntityCode,
                    relatedEntity: entityItem.relatedEntity,
                    document: document,
                  });
                }}
                selectedIds={entityItem.selectedIds}
                reloadDocuments={reloadDocuments}
                testidSuffix={`${entity.relatedEntityCode}-${
                  entityItem.relatedEntity?.[entity.idField]
                }`}
              />
            )}
          </Fragment>
        ))}
      </DocumentEntity>
    ),
    [documentsTreeDispatch, t, reloadDocuments],
  );

  const getDocumentConfigurationItem = useCallback(
    (entity: DocumentsTreeData[0]) => (
      <DocumentEntity key={entity.relatedEntityCode} title={t(entity.tKey)}>
        {entity.relatedEntities.map(entityItem => (
          <DocumentsList
            // normally there will be only one entityItem
            key={'cfg'}
            isCaretVisible
            isCheckVisible
            isAddFileVisible={isAllowUploadFile}
            isAddLinkVisible={isAllowUploadLink}
            documents={entityItem.documents}
            relatedEntity={entityItem.relatedEntity}
            relatedEntityCode={entity.relatedEntityCode}
            onCheck={document => {
              documentsTreeDispatch({
                type: DocumentTreeDataActionType.selectDocument,
                relatedEntityCode: entity.relatedEntityCode,
                relatedEntity: entityItem.relatedEntity,
                document: document,
              });
            }}
            selectedIds={entityItem.selectedIds}
            reloadDocuments={reloadDocuments}
            testidSuffix={`configuration`}
          />
        ))}

        <ScPublishButtonWrapper>
          <DocumentTemplateDropdown
            templates={filteredPdfTemplates}
            onAction={onPublish}
            stubTitle={t('TOTAL_NOT_FOUND_TEMPLATES')}
            placement="bottomCenter"
            trigger={['click']}
            withConfirm={true}
            confirmPlacement="right"
            confirmOffsetX={17}
            width="100%"
            disabled={!isAllowDocumentsConfigurationsPublish}
          >
            <Button fullwidth variant="primary">
              {t('DOCUMENT_GENERATE_FROM_TEMPLATE')}
            </Button>
          </DocumentTemplateDropdown>
        </ScPublishButtonWrapper>
      </DocumentEntity>
    ),
    [
      documentsTreeDispatch,
      t,
      isAllowDocumentsConfigurationsPublish,
      filteredPdfTemplates,
      onPublish,
      reloadDocuments,
      isAllowUploadFile,
      isAllowUploadLink,
    ],
  );

  return (
    <Layout withVerticalScrolling>
      <ScDocumentsHeader>{t('DOCUMENT_SELECT_TO_SEND')}</ScDocumentsHeader>

      <Masonry
        breakpointCols={{
          default: 3,
          // library offsets values, so they need re-calculate on our side
          [sizes.lg - 1]: 2,
          [sizes.md - 1]: 1,
        }}
      >
        {documentsTreeState.map(entity => {
          const isConfiguration =
            entity.relatedEntityCode === DocumentRelatedEntityCode.configuration;

          if (isConfiguration) return getDocumentConfigurationItem(entity);
          return getDocumentPlainItem(entity);
        })}
      </Masonry>
      <DocumentsFooter
        documentsSchema={documentsTreeState}
        handleGoToPrevStep={handlePrevStep}
        sendQuoteEmailEnabled={isConfigurationComplete}
        sendQuoteEmailVisible={isAllowDocumentsPageEmailButton}
        sendQuoteCustomEmailUrls={sendQuoteCustomEmailUrls}
        handleDownloadPdfDocuments={handleDownloadPdfDocuments}
        downloadPdfDocumentsEnabled={Boolean(
          selectedDocumentFiles.length && isConfigurationComplete,
        )}
        downloadPdfDocumentsVisible={isAllowDocumentsPageGetPDFButton}
      />
    </Layout>
  );
};

import {
  SoftOfferOccasionalDrivers,
  SoftOfferOccasionalDriversFields,
} from 'types/vendor';

export const getInitialValues = (
  initials?: Partial<SoftOfferOccasionalDrivers>,
): SoftOfferOccasionalDrivers =>
  ({
    [SoftOfferOccasionalDriversFields.id]: 0,
    [SoftOfferOccasionalDriversFields.offerId]: '',
    [SoftOfferOccasionalDriversFields.changedAt]: null,
    [SoftOfferOccasionalDriversFields.changedBy]: '',

    [SoftOfferOccasionalDriversFields.driver1LastName]: '',
    [SoftOfferOccasionalDriversFields.driver1Firstname]: '',
    [SoftOfferOccasionalDriversFields.driver1Street]: '',
    [SoftOfferOccasionalDriversFields.driver1Number]: '',
    [SoftOfferOccasionalDriversFields.driver1Box]: '',
    [SoftOfferOccasionalDriversFields.driver1ZipCode]: '',
    [SoftOfferOccasionalDriversFields.driver1Locality]: '',
    [SoftOfferOccasionalDriversFields.driver1Language]: '',
    [SoftOfferOccasionalDriversFields.driver1Tel]: '',
    [SoftOfferOccasionalDriversFields.driver1Fax]: '',
    [SoftOfferOccasionalDriversFields.driver1Sex]: '',
    [SoftOfferOccasionalDriversFields.driver1Gsm]: '',
    [SoftOfferOccasionalDriversFields.driver1BirthdayAt]: null,
    [SoftOfferOccasionalDriversFields.driver1LicenceCategoryCode]: '',
    [SoftOfferOccasionalDriversFields.driver1Email]: '',
    [SoftOfferOccasionalDriversFields.driver1LicenceAt]: null,
    [SoftOfferOccasionalDriversFields.driver1Job]: '',

    [SoftOfferOccasionalDriversFields.driver2LastName]: '',
    [SoftOfferOccasionalDriversFields.driver2Firstname]: '',
    [SoftOfferOccasionalDriversFields.driver2Street]: '',
    [SoftOfferOccasionalDriversFields.driver2Number]: '',
    [SoftOfferOccasionalDriversFields.driver2Box]: '',
    [SoftOfferOccasionalDriversFields.driver2ZipCode]: '',
    [SoftOfferOccasionalDriversFields.driver2Locality]: '',
    [SoftOfferOccasionalDriversFields.driver2Language]: '',
    [SoftOfferOccasionalDriversFields.driver2Tel]: '',
    [SoftOfferOccasionalDriversFields.driver2Fax]: '',
    [SoftOfferOccasionalDriversFields.driver2Sex]: '',
    [SoftOfferOccasionalDriversFields.driver2Gsm]: '',
    [SoftOfferOccasionalDriversFields.driver2BirthdayAt]: null,
    [SoftOfferOccasionalDriversFields.driver2LicenceCategoryCode]: '',
    [SoftOfferOccasionalDriversFields.driver2Email]: '',
    [SoftOfferOccasionalDriversFields.driver2LicenceAt]: null,
    [SoftOfferOccasionalDriversFields.driver2Job]: '',
    ...initials,
  } as SoftOfferOccasionalDrivers);

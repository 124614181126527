import {
  ModelAction,
  ModelActionFields,
  ModelActionSignValues,
  ModelFields,
  ModelItemFields,
  ModelMachineConfiguration,
  ModelMachineConfigurationFields,
  ModelMarginItemFields,
  ModelUpdateAttributesObjectFields,
  Model_CommonCategoryFields,
  Model_UpdGlobalDiscount_Fields,
  ModelTotalsItem,
} from 'types/vendor';
import {
  DOCUMENT_GENERATION_DOCTYPE_DRAFT,
  TOTAL_DEALER_ACTION_ADDITIONAL_COST_LIMIT,
  TOTAL_DEFAULT_FILENAME,
} from 'common/constants';
import {
  defaultGlobalFeaturesFlags,
  GlobalFeaturesFlagsFields,
} from 'common/globalFeaturesFlags';
import { DocumentTemplate } from 'types/vendor';
import { useFeature } from 'context/feature/FeatureProvider';
import { useModelApi } from 'context/model/useModelApi';
import { useStreaming } from 'context/streaming/StreamingProvider';
import useCarInfo from 'hooks/useCarInfo';
import React, { FC, useCallback, useContext, useMemo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'utils';
import { TOTAL_PUBLISH_DOCUMENT_TYPE } from 'utils/constants';
import { saveByUrl } from 'utils/file-save';
import { notification, NotificationMessageByStatus } from 'utils/notification';
import { Status } from 'utils/types';
import {
  extractDealerAction,
  extractTotalData,
  getTotalPageAdaptedFields,
  TotalPageAdaptedFields,
} from './utils';
import {
  handleChangeAmount,
  handleChangeBalance,
  handleChangePercent,
} from './utils/dealerAction';
import { useConfiguration } from 'context/configuration/ConfigurationProvider';
import useInconfiguredCategory from 'hooks/useInconfiguredCategory';
import { Configuration_GetDetailsById_Output_Configuration_Fields as ConfDetailsFields } from '@hypercharge/xdms-client/lib/configuration/types';
import { BannerSettings } from './sectionsDynamic/types';
import { useSelector } from 'react-redux';
import {
  configurationSelectors,
  featuresFlagsSelectors,
  modelSelectors,
  sharedSelectors,
} from 'store';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { useDocumentTypesApi } from 'context/document/useDocumentTypesApi';
import { DocumentItem, DocumentRelatedEntityCode } from 'context/document/types';
import { StreamingEventType } from 'context/streaming/types';

export type MarginChangeParams = {
  value: number;
  type: 'percent' | 'amount' | 'balance';
};

interface ConfigurationDetailsImage {
  source: string;
  alt: string;
  title: string;
  key: string | number;
}

const ORDERED_CONFIGURATION_DETAILS_SIDES = [
  'threeQuartersFrontLeft',
  'threeQuartersRearLeft',
  'wheel',
  'driversView',
  'rear',
];

type ContextValue = {
  isShowHODFeatureEnabled: boolean;
  isShowSalesInfoFeatureEnabled: boolean;
  isShowOptionalFeatureEnabled: boolean;
  isShowDeselectedFeatureEnabled: boolean;
  isHideEmptyCollapsibleRowsFeatureEnabled: boolean;
  isShowOptionsSplit: boolean;
  isAllowTotalDocumentPrintFeatureEnabled: boolean;
  isAllowTotalLimitBalanceValue: boolean;
  isAllowInfoPageSalesCode: boolean;
  isAllowInfoPageSellCode: boolean;
  isTotalTradeInsSeparateViewFeatureEnabled: boolean;
  isRequiredCategoriesFeatureEnabled: boolean;
  hideZeroDiscounts: boolean;
  hideIfEmpty: boolean;

  FIELDS: TotalPageAdaptedFields;

  totalData: ModelTotalsItem | null;
  machineConfiguration: ModelMachineConfiguration | null;
  configurationDetailsImages: ConfigurationDetailsImage[];
  dealerAction: ModelAction | undefined;
  actionsList: ModelAction[];
  isLoading: boolean;
  modelId: string | null;
  modelPublishAllowed: boolean;
  maxBalanceValue: number | undefined;
  salesCode: string | undefined;
  sellCode: string | undefined;
  getPublishedDocumentUrl(documents: DocumentItem[]): string;
  getSummaryTransparentImage(): string;
  publishConfiguration(template?: DocumentTemplate): Promise<void>;
  updateGlobalDiscount(code: string, value: number | string): Promise<void>;
  updateBaseModelPrice(value: number): Promise<void>;
  handleMarginChange(params: MarginChangeParams): void;
  printDocument(template?: DocumentTemplate): Promise<void>;
  handleToggleSign(record: ModelAction): void;
  requiredItems: [string, boolean][];
  bannerSettingsList: BannerSettings[];
  /* + single action operations */
  updateActionAmount(record: ModelAction, value: number): Promise<void>;
  updateActionPercent(record: ModelAction, value: number): Promise<void>;
  updateActionName(record: ModelAction, value: string): Promise<void>;
  toggleActionIsSelected(record: ModelAction): Promise<void>;
  toggleActionSign(record: ModelAction): Promise<void>;
  /* - single action operations */
};

const FeatureContext = React.createContext<ContextValue | undefined>(undefined);

const TotalPageProvider: FC<PropsWithChildren<{ value?: ContextValue }>> = props => {
  const { t } = useTranslation();

  const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);

  const { isFeatureEnabled } = useFeature();
  const { updateModel } = useModelApi();
  const { data: carInfoData } = useCarInfo();

  const { getDocumentsList, uploadDocumentLink, generateConfigurationDocument } =
    useDocumentsApi();
  const { getDocumentTypes } = useDocumentTypesApi();
  const { getConfigurationDetailsById } = useConfiguration();
  const { inconfiguredCategories, requiredCategories } = useInconfiguredCategory();
  const { sendMessage } = useStreaming();

  const shouldShowPricesWithVAT = useSelector(sharedSelectors.getShouldShowPricesWithVAT);
  const { model, status } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);
  const { configurationNumber, configurationModelName, configurationDetails } =
    useSelector(configurationSelectors.getAll);

  const isShowHODFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalShowHOD,
  });
  const isShowSalesInfoFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalShowSalesInfo,
  });
  const isShowOptionalFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalShowOptional,
  });
  const isShowDeselectedFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalShowDeselected,
  });

  const isHideEmptyCollapsibleRowsFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowHideEmptyCollapsibleRows,
  });

  const isShowOptionsSplit = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalSplitOptions,
  });
  const isAllowTotalShowZeroDiscountFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalShowZeroDiscount,
  });

  const isAllowTotalDocumentPrintFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalDocumentPrint,
  });
  const isAllowTotalLimitBalanceValue = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalLimitBalanceValue,
  });

  const isAllowInfoPageSalesCode = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInfoPageSalesCode,
  });

  const isAllowInfoPageSellCode = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowInfoPageSellCode,
  });

  const isRequiredCategoriesFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.RequiredCategories,
  });

  const isGeneratePublishImageFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalGeneratePublishImage,
  });

  const isTotalTradeInsSeparateViewFeatureEnabled = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTotalTradeInsSeparateView,
  });

  const bannerSettingsList = useMemo<BannerSettings[]>(() => {
    return (
      globalFeatures?.[GlobalFeaturesFlagsFields.TotalPageBannersSettings] ??
      defaultGlobalFeaturesFlags[GlobalFeaturesFlagsFields.TotalPageBannersSettings]
    );
  }, [globalFeatures]);

  const hideZeroDiscounts = useMemo(
    () => !isAllowTotalShowZeroDiscountFeatureEnabled,
    [isAllowTotalShowZeroDiscountFeatureEnabled],
  );
  const hideIfEmpty = useMemo(
    () => isHideEmptyCollapsibleRowsFeatureEnabled,
    [isHideEmptyCollapsibleRowsFeatureEnabled],
  );

  const FIELDS = useMemo<TotalPageAdaptedFields>(
    () => getTotalPageAdaptedFields(shouldShowPricesWithVAT),
    [shouldShowPricesWithVAT],
  );

  const totalData = useMemo<ModelTotalsItem | null>(
    () => model && extractTotalData(model),
    [model],
  );

  const machineConfiguration = useMemo<ModelMachineConfiguration | null>(
    () => get(model, [ModelFields.machineConfiguration, 0], null),
    [model],
  );

  const configurationDetailsImages = useMemo<ConfigurationDetailsImage[]>(() => {
    const [visualization] = carInfoData?.visualizations ?? [];
    if (!visualization) return [];

    const { interior, exterior } = visualization.views;
    const views: Record<string, string | null | undefined> = {
      threeQuartersFrontLeft: exterior.studio?.threeQuartersFrontLeft?.sizes?.large?.url,
      threeQuartersRearLeft: exterior.studio?.threeQuartersRearLeft?.sizes?.large?.url,
      wheel: exterior.details.wheel?.sizes?.large?.url,
      driversView: interior.studio?.driversView?.sizes?.large?.url,
      rear: interior.studio?.rear?.sizes?.large?.url,
    };

    if (!Object.values(views).length) return [];

    return ORDERED_CONFIGURATION_DETAILS_SIDES.filter(side => views[side]).map(side => ({
      source: String(views[side]),
      alt: side,
      title: side,
      key: side,
    }));
  }, [carInfoData]);

  const dealerAction = useMemo<ModelAction | undefined>(() => {
    if (!model) return;
    return extractDealerAction(model);
  }, [model]);

  const actionsList = useMemo<ModelAction[]>(() => {
    return get(model, ModelFields.actions, []);
  }, [model]);

  const isLoading = useMemo(() => status === Status.Loading, [status]);

  const modelId = useMemo<string | null>(
    () => get(model, [ModelFields.model, 0, ModelItemFields.modelNumber]),
    [model],
  );

  const modelPublishAllowed = useMemo<boolean>(() => {
    return get(
      model,
      [ModelFields.modelMargin, 0, ModelMarginItemFields.publishAllowed],
      false,
    );
  }, [model]);

  const maxBalanceValue = useMemo<number | undefined>(() => {
    if (!isAllowTotalLimitBalanceValue) return undefined;
    const value = get(totalData, FIELDS.dealerDiscount.totalWithoutDealerDelta, 0);
    if (value < 0) return;
    return value + TOTAL_DEALER_ACTION_ADDITIONAL_COST_LIMIT;
  }, [
    isAllowTotalLimitBalanceValue,
    totalData,
    FIELDS.dealerDiscount.totalWithoutDealerDelta,
  ]);

  const salesCode = useMemo(
    () => configurationDetails?.configuration[ConfDetailsFields.salesCode],
    [configurationDetails],
  );

  const sellCode = useMemo(
    () => configurationDetails?.configuration[ConfDetailsFields.sellCode],
    [configurationDetails],
  );

  const getPublishedDocumentUrl: ContextValue['getPublishedDocumentUrl'] = useCallback(
    documents => {
      if (Array.isArray(documents)) {
        const file = documents.find(document => document.computed.isFile);
        if (file) return file.fileUrl;
      }
      return '';
    },
    [],
  );

  const getSummaryTransparentImage = useCallback(() => {
    const [visualization] = carInfoData?.visualizations ?? [];
    if (!visualization) return '';

    const imageView = visualization.views.exterior.studio?.threeQuartersFrontLeft;
    return imageView?.sizes?.large?.transparentUrl ?? '';
  }, [carInfoData]);

  const publishConfiguration = useCallback(
    async (template?: DocumentTemplate) => {
      if (!model) return;
      const updatedModel = template
        ? {
            ...model,
            [ModelFields.params]: [
              ...get(model, ModelFields.params, []),
              {
                NMparam: 'INlayout',
                VAparam: JSON.stringify(template),
              },
            ],
          }
        : model;
      {
        // @todo: remove any
        const machineConfiguration: any = get(
          model,
          `${ModelFields.machineConfiguration}.0`,
          {},
        );
        const { messageHandled, status } = await updateModel(updatedModel, {
          [ModelUpdateAttributesObjectFields.machineConfiguration]: {
            ...machineConfiguration,
            [ModelMachineConfigurationFields.complete]: true,
          },
        });

        if (modelToken) {
          sendMessage({
            type: StreamingEventType.EMIT_SLOT_CHANGE,
            data: {
              name: 'selection',
              data: {
                token: modelToken,
              },
            },
          });
        }

        if (!messageHandled) {
          notification.openByStatus(status, {
            [Status.Success]: t('TOTAL_SUCCESS_PUBLISH_MESSAGE'),
            [Status.Error]: t('GLOBAL_ERROR_TEXT'),
          });
        }
      }

      if (isGeneratePublishImageFeatureEnabled) {
        const { response: documentTypes } = await getDocumentTypes();
        const documentType = documentTypes?.find(
          ({ id }) => id === TOTAL_PUBLISH_DOCUMENT_TYPE,
        );
        if (!documentType) {
          notification.openByStatus(Status.Error, {
            [Status.Error]: t('TOTAL_PUBLISH_NOT_FOUND_DOCUMENT_TYPE'),
          });
          return;
        }
        const [documentLink] = configurationDetailsImages;
        if (!documentLink?.source) return;
        await uploadDocumentLink(
          {
            name: `${configurationNumber}-email-image-${configurationModelName}`,
            typeCategory: TOTAL_PUBLISH_DOCUMENT_TYPE,
            type: TOTAL_PUBLISH_DOCUMENT_TYPE,
            url: documentLink.source,
          },
          DocumentRelatedEntityCode.configuration,
          undefined,
        );
      }

      const { response } = await getDocumentsList(
        DocumentRelatedEntityCode.configuration,
        undefined,
      );
      const documentSrc = getPublishedDocumentUrl(response ?? []);
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'summary',
          data: {
            documentSrc,
            imageSrc: getSummaryTransparentImage(),
          },
        },
      });
      if (configurationNumber) {
        await getConfigurationDetailsById({ configurationNumber: configurationNumber });
      }
    },
    [
      model,
      isGeneratePublishImageFeatureEnabled,
      getDocumentsList,
      getPublishedDocumentUrl,
      sendMessage,
      getSummaryTransparentImage,
      configurationNumber,
      updateModel,
      modelToken,
      t,
      getDocumentTypes,
      configurationDetailsImages,
      uploadDocumentLink,
      configurationModelName,
      getConfigurationDetailsById,
    ],
  );

  /** AKA Draft */
  const printDocument = useCallback(
    async printTemplate => {
      const { response: documentTypes } = await getDocumentTypes();
      const draftDocumentType = documentTypes?.find(
        ({ id }) => id === DOCUMENT_GENERATION_DOCTYPE_DRAFT,
      );
      if (!draftDocumentType) {
        notification.openByStatus(Status.Error, {
          [Status.Error]: t('TOTAL_PRINT_NOT_FOUND_DOCUMENT_TYPE'),
        });
        return;
      }
      const { response } = await generateConfigurationDocument(
        printTemplate,
        draftDocumentType,
      );
      if (response) {
        let fileName = TOTAL_DEFAULT_FILENAME;
        const documents = await getDocumentsList(
          DocumentRelatedEntityCode.configuration,
          undefined,
        );
        if (Array.isArray(documents)) {
          const generatedDocument = documents.find(
            document => document.fileUrl === response,
          );
          if (generatedDocument) {
            fileName = generatedDocument.name.documentName ?? fileName;
          }
        }
        await saveByUrl(response, fileName, {
          charset: 'utf-8',
          mimeType: 'application/pdf',
        });
        window.open(response, '_blank');
      }
    },
    [generateConfigurationDocument, getDocumentTypes, getDocumentsList, t],
  );

  const handleMarginChange = useCallback(
    async (params: MarginChangeParams) => {
      if (!model) return;

      const dealerAction = extractDealerAction(model);
      const totalData = extractTotalData(model);

      if (!dealerAction) return;

      let newDealerAction: ModelAction | undefined;

      switch (params.type) {
        case 'percent':
          newDealerAction = handleChangePercent(params.value, dealerAction, FIELDS);
          break;
        case 'amount':
          newDealerAction = handleChangeAmount(
            params.value,
            dealerAction,
            FIELDS,
            shouldShowPricesWithVAT,
          );
          break;
        case 'balance':
          newDealerAction = handleChangeBalance(
            params.value,
            dealerAction,
            FIELDS,
            totalData,
          );
          break;
      }

      if (!model || !newDealerAction) return;

      const { status, messageHandled } = await updateModel(model, {
        [ModelUpdateAttributesObjectFields.actions]: newDealerAction,
      });

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: {
              token: modelToken,
            },
          },
        });
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
          [Status.Success]: t('TABLE_TOTAL_RECALCULATED_SUCCESS'),
        });
      }
    },
    [model, updateModel, sendMessage, modelToken, FIELDS, shouldShowPricesWithVAT, t],
  );

  const handleToggleSign = useCallback(
    async (record: ModelAction): Promise<void> => {
      if (!model) return;

      const nextSign =
        record[ModelActionFields.sign] === ModelActionSignValues.positive
          ? ModelActionSignValues.negative
          : ModelActionSignValues.positive;

      const { status, messageHandled } = await updateModel(model, {
        [ModelUpdateAttributesObjectFields.actions]: {
          ...record,
          [ModelActionFields.sign]: nextSign,
        },
      });

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: {
              token: modelToken,
            },
          },
        });
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TABLE_TOTAL_RECALCULATED_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }
    },
    [model, modelToken, sendMessage, t, updateModel],
  );

  const updateGlobalDiscount = useCallback(
    async (code: string, value: number | string) => {
      if (!model) return;

      const { status, messageHandled } = await updateModel(model, {
        [ModelUpdateAttributesObjectFields.updateGlobalDiscounts]: [
          {
            [Model_UpdGlobalDiscount_Fields.percent]: value,
            [Model_UpdGlobalDiscount_Fields.code]: code,
          },
        ],
      });

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: {
              token: modelToken,
            },
          },
        });
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TABLE_TOTAL_RECALCULATED_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }
    },
    [model, updateModel, sendMessage, modelToken, t],
  );

  const updateBaseModelPrice = useCallback(
    async (value: number) => {
      if (!model) return;

      const field = shouldShowPricesWithVAT
        ? ModelMachineConfigurationFields.basePriceAmount_withVAT
        : ModelMachineConfigurationFields.basePriceAmount;
      const nullField = shouldShowPricesWithVAT
        ? ModelMachineConfigurationFields.basePriceAmount
        : ModelMachineConfigurationFields.basePriceAmount_withVAT;

      const { messageHandled, status } = await updateModel(model, {
        [ModelUpdateAttributesObjectFields.machineConfiguration]: {
          ...get(model, [ModelFields.machineConfiguration, 0], {}),
          [field]: value,
          [nullField]: 0,
        },
      });

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: {
              token: modelToken,
            },
          },
        });
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TABLE_TOTAL_RECALCULATED_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
        });
      }
    },
    [model, shouldShowPricesWithVAT, updateModel, sendMessage, modelToken, t],
  );

  const requiredItems = useMemo<[string, boolean][]>(() => {
    const items: [string, boolean][] = [
      [t('TOTAL_CONFIGURATION_PUBLISHABLE_MESSAGE'), modelPublishAllowed],
    ];

    if (isRequiredCategoriesFeatureEnabled) {
      const inconfiguredCategoriesHash = Object.values(inconfiguredCategories)
        .flat()
        .reduce<Record<string, string>>((hash, category) => {
          const categoryName = category?.[Model_CommonCategoryFields.name];
          if (categoryName) hash[categoryName] = categoryName;
          return hash;
        }, {});

      Object.values(requiredCategories)
        .flat()
        .forEach(category => {
          const categoryName = category?.[Model_CommonCategoryFields.name];
          if (!categoryName) return;
          const isConfigured = !inconfiguredCategoriesHash[categoryName];
          items.push([
            t('TOTAL_REQUIRED_ITEM_IS_SELECTED', { displayName: categoryName }),
            isConfigured,
          ]);
        });
    }

    if (isAllowInfoPageSalesCode) {
      items.push([
        t('TOTAL_REQUIRED_ITEM_IS_SELECTED', { displayName: t('INFO_SALES_CODE') }),
        Boolean(salesCode),
      ]);
    }

    if (isAllowInfoPageSellCode) {
      items.push([
        t('TOTAL_REQUIRED_ITEM_IS_SELECTED', { displayName: t('INFO_SELL_CODE') }),
        Boolean(sellCode),
      ]);
    }

    return items;
  }, [
    t,
    modelPublishAllowed,
    isRequiredCategoriesFeatureEnabled,
    isAllowInfoPageSalesCode,
    isAllowInfoPageSellCode,
    inconfiguredCategories,
    requiredCategories,
    salesCode,
    sellCode,
  ]);

  /* + single action operations */
  const updateAction = useCallback(
    async (record: ModelAction, messages?: NotificationMessageByStatus) => {
      if (!model) return;
      const { status, messageHandled } = await updateModel(model, {
        [ModelUpdateAttributesObjectFields.actions]: record,
      });

      if (modelToken) {
        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'selection',
            data: {
              token: modelToken,
            },
          },
        });
      }

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t('TABLE_TOTAL_RECALCULATED_SUCCESS'),
          [Status.Error]: t('GLOBAL_ERROR_TEXT'),
          ...messages,
        });
      }
    },
    [model, updateModel, sendMessage, modelToken, t],
  );

  const updateActionAmount = useCallback(
    async (record: ModelAction, value: number): Promise<void> => {
      const oldValue = record[FIELDS.dealerAction.amount];
      if (value === oldValue) return;
      await updateAction({
        ...record,
        [FIELDS.dealerAction.amount]: value,
        [FIELDS.dealerAction.amountReverse]: 0,
        [ModelActionFields.isSelected]: value > 0,
      });
    },
    [updateAction, FIELDS],
  );

  const updateActionPercent = useCallback(
    async (record: ModelAction, value: number): Promise<void> => {
      const oldValue = record[ModelActionFields.percent];
      if (value === oldValue) return;
      await updateAction({
        ...record,
        [FIELDS.dealerAction.percent]: value,
        [FIELDS.dealerAction.isSelected]: value > 0,
      });
    },
    [FIELDS, updateAction],
  );

  const updateActionName = useCallback(
    async (record: ModelAction, value: string): Promise<void> => {
      const oldValue = record[ModelActionFields.name];
      if (value === oldValue) return;
      await updateAction(
        {
          ...record,
          [FIELDS.dealerAction.name]: value,
        },
        {
          [Status.Success]: t('UPDATE_SUCCESSFUL'),
        },
      );
    },
    [t, updateAction, FIELDS],
  );

  const toggleActionIsSelected = useCallback(
    async (record: ModelAction): Promise<void> => {
      await updateAction({
        ...record,
        [FIELDS.dealerAction.isSelected]: !record[ModelActionFields.isSelected],
      });
    },
    [updateAction, FIELDS],
  );

  const toggleActionSign = useCallback(
    async (record: ModelAction): Promise<void> => {
      const nextSign =
        record[ModelActionFields.sign] === ModelActionSignValues.positive
          ? ModelActionSignValues.negative
          : ModelActionSignValues.positive;

      await updateAction({
        ...record,
        [FIELDS.dealerAction.sign]: nextSign,
      });
    },
    [updateAction, FIELDS],
  );
  /* - single action operations */

  const value = useMemo(
    () => ({
      isShowHODFeatureEnabled,
      isShowSalesInfoFeatureEnabled,
      isShowOptionalFeatureEnabled,
      isShowDeselectedFeatureEnabled,
      isHideEmptyCollapsibleRowsFeatureEnabled,
      isShowOptionsSplit,
      isAllowTotalDocumentPrintFeatureEnabled,
      isAllowTotalLimitBalanceValue,
      isAllowInfoPageSalesCode,
      isAllowInfoPageSellCode,
      isRequiredCategoriesFeatureEnabled,
      isTotalTradeInsSeparateViewFeatureEnabled,
      hideZeroDiscounts,
      hideIfEmpty,
      FIELDS,
      totalData,
      machineConfiguration,
      configurationDetailsImages,
      dealerAction,
      actionsList,
      isLoading,
      modelId,
      modelPublishAllowed,
      maxBalanceValue,
      salesCode,
      sellCode,
      publishConfiguration,
      updateGlobalDiscount,
      updateBaseModelPrice,
      handleMarginChange,
      printDocument,
      handleToggleSign,
      requiredItems,
      bannerSettingsList,
      updateActionAmount,
      updateActionPercent,
      updateActionName,
      toggleActionIsSelected,
      toggleActionSign,
      getPublishedDocumentUrl,
      getSummaryTransparentImage,
    }),
    [
      isShowHODFeatureEnabled,
      isShowSalesInfoFeatureEnabled,
      isShowOptionalFeatureEnabled,
      isShowDeselectedFeatureEnabled,
      isHideEmptyCollapsibleRowsFeatureEnabled,
      isShowOptionsSplit,
      isAllowTotalDocumentPrintFeatureEnabled,
      isAllowTotalLimitBalanceValue,
      isAllowInfoPageSalesCode,
      isAllowInfoPageSellCode,
      isRequiredCategoriesFeatureEnabled,
      isTotalTradeInsSeparateViewFeatureEnabled,
      hideZeroDiscounts,
      hideIfEmpty,
      FIELDS,
      totalData,
      machineConfiguration,
      configurationDetailsImages,
      dealerAction,
      actionsList,
      isLoading,
      modelId,
      modelPublishAllowed,
      maxBalanceValue,
      salesCode,
      sellCode,
      publishConfiguration,
      updateGlobalDiscount,
      updateBaseModelPrice,
      handleMarginChange,
      printDocument,
      handleToggleSign,
      requiredItems,
      bannerSettingsList,
      updateActionAmount,
      updateActionPercent,
      updateActionName,
      toggleActionIsSelected,
      toggleActionSign,
      getPublishedDocumentUrl,
      getSummaryTransparentImage,
    ],
  );

  return <FeatureContext.Provider value={value} {...props} />;
};

const useTotalPage = (): ContextValue => {
  const context = useContext(FeatureContext);

  if (context === undefined) {
    throw new Error(
      `${useTotalPage.name} must be used within an ${TotalPageProvider.name}`,
    );
  }

  return context;
};

export { TotalPageProvider, useTotalPage };

import {
  FinancingCarInfoFields,
  FinancingCarInfo,
  FinancingOptionsKeys,
  OlistItemOption,
  OlistItemOptionFields,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useEffect, useMemo } from 'react';
import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import { DatePicker } from 'components/form/DatePicker';
import { DATE_FORMAT } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider, Form } from 'formik';
import { getSchema } from './schema';
import { getInitialValues } from './initial';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import { isFieldRequired } from 'utils/is-field-required';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';
import { financingSelectors } from 'store';
import { useSelector } from 'react-redux';

const ROW_SPACES: [number, number] = [20, 0];
const SUB_ROW_SPACES: [number, number] = [16, 0];

export const STATUSES = [
  { value: 'NEW', tKey: 'FINANCING_STATUS_NEW' },
  { value: 'USED', tKey: 'FINANCING_STATUS_USED' },
];

interface Props {
  isCreate: boolean;
  isDisabled: boolean;
  initialValues?: FinancingCarInfo;
  onSubmit(values: FinancingCarInfo): void;
  setIsTabLocked?(value: boolean): void;
}

const CarInfoForm: FC<Props> = ({
  onSubmit,
  initialValues,
  isCreate,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t } = useTranslation();

  const options = useSelector(financingSelectors.getOptions);

  const fuelCodes = options?.[FinancingOptionsKeys.Fuel]?.options ?? [];

  const schema = getSchema(t);

  const initialData = useMemo(() => {
    const defaultValues = getInitialValues({
      [FinancingCarInfoFields.fuelCode]:
        options?.[FinancingOptionsKeys.Fuel]?.initialOption,
    });

    if (isCreate) {
      return {
        ...defaultValues,
        ...initialValues,
      };
    }

    return initialValues ?? defaultValues;
  }, [initialValues, isCreate, options]);

  const formProps = useFormik({
    initialValues: initialData,
    onSubmit: values => onSubmit(values),
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { dirty } = formProps;

  const statusOptions = useMemo(() => {
    const list = STATUSES.map(({ value, tKey }) => ({
      value,
      label: t(tKey),
    }));
    return mapToSelectOptions(list, {
      label: 'label',
      value: 'value',
    });
  }, [t]);

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  return (
    <FormikProvider value={formProps}>
      <Form>
        <Row gutter={ROW_SPACES}>
          <Col md={12}>
            <FormField
              name={FinancingCarInfoFields.brand}
              component={Input}
              required={isFieldRequired(schema, FinancingCarInfoFields.brand)}
              disabled={true}
              label={t('FINANCING_BRAND')}
            />

            <FormField
              name={FinancingCarInfoFields.modelName}
              component={Input}
              required={isFieldRequired(schema, FinancingCarInfoFields.modelName)}
              disabled={true}
              label={t('FINANCING_MODEL_NAME')}
            />

            <FormField
              name={FinancingCarInfoFields.modelNumber}
              component={Input}
              required={isFieldRequired(schema, FinancingCarInfoFields.modelNumber)}
              disabled={true}
              label={t('FINANCING_MODEL_NUMBER')}
            />

            <FormField
              name={FinancingCarInfoFields.salePrice}
              component={InputNumberLocalized}
              required={isFieldRequired(schema, FinancingCarInfoFields.salePrice)}
              disabled={true}
              label={t('FINANCING_SALE_PRICE')}
            />
          </Col>
          <Col md={12}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCarInfoFields.fuelCode}
                  component={Select}
                  options={mapToSelectOptions<OlistItemOption>(fuelCodes, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(schema, FinancingCarInfoFields.fuelCode)}
                  disabled={isDisabled}
                  label={t('FINANCING_FUEL_CODE')}
                  showSearch
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={FinancingCarInfoFields.fuelCode2}
                  component={Select}
                  options={mapToSelectOptions<OlistItemOption>(fuelCodes, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(schema, FinancingCarInfoFields.fuelCode2)}
                  disabled={isDisabled}
                  label={t('FINANCING_FUEL_CODE_2')}
                  showSearch
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  name={FinancingCarInfoFields.status}
                  component={Select}
                  options={statusOptions}
                  required={isFieldRequired(schema, FinancingCarInfoFields.status)}
                  disabled={isDisabled}
                  label={t('FINANCING_STATUS')}
                  showSearch
                />
              </Col>
              <Col md={12}>
                <FormField
                  name={FinancingCarInfoFields.firstRegistrationAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(
                    schema,
                    FinancingCarInfoFields.firstRegistrationAt,
                  )}
                  disabled={isDisabled}
                  label={t('FINANCING_FIRST_REGISTRATION_AT')}
                />
              </Col>
            </Row>

            <FormField
              name={FinancingCarInfoFields.financialNumber}
              component={Input}
              required={isFieldRequired(schema, FinancingCarInfoFields.financialNumber)}
              disabled={isDisabled}
              label={t('FINANCING_FINANCING_NUMBER')}
            />
          </Col>
        </Row>

        <Footer isCreate={isCreate} isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default CarInfoForm;

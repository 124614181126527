import React, { FC, useEffect } from 'react';
import { SoftOfferTable } from './SoftOfferTable';
import { SoftOfferForm } from './SoftOfferForm';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SOFT_OFFER_URL } from 'common/constants';
import { useSoftOfferApi } from 'context/softOffer/SoftOfferProvider';
import { useSelector } from 'react-redux';
import { configurationSelectors, sharedSelectors } from 'store';
import { useFeature } from 'context/feature/FeatureProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';

export const SoftOffer: FC = () => {
  const { getSoftOffersList, loadSoftOfferOptions } = useSoftOfferApi();
  /** Have it here to prevent /create page being initially considered as /:id page */
  const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);
  const { isFeatureEnabled } = useFeature();

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );

  const isAllowAddNewSoftOffer = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferAddNew,
  });

  /** Its purpose mostly in fetching list of options */
  useEffect(() => {
    if (!configurationNumber) return;
    getSoftOffersList(configurationNumber).then(({ response }) => {
      if (!response?.length) loadSoftOfferOptions();
    });
  }, [configurationNumber, getSoftOffersList, loadSoftOfferOptions]);

  return (
    <Switch>
      <Route path={SOFT_OFFER_URL + '/list'} component={SoftOfferTable} />
      {(isAllowAddNewSoftOffer || isWholePageLoading) && (
        <Route
          path={SOFT_OFFER_URL + '/create'}
          render={() => <SoftOfferForm isCreate />}
        />
      )}
      <Route path={SOFT_OFFER_URL + '/:id'} component={SoftOfferForm} />
      <Redirect to={SOFT_OFFER_URL + '/list'} />
    </Switch>
  );
};

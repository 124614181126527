import { Message, ModelFields } from '@hypercharge/xdms-client/lib/types';
import React, {
  FC,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react';
import { STORAGE_KEYS, URL_QUERY_PARAMS } from 'common/constants';
import { loadFromLocalStorage, saveInLocalStorage } from 'utils/storage';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../language/LanguageProvider';
import { useQuery } from '../router/UrlQueryProvider';
import { useRelations } from '../relations/RelationsProvider';
import { useModelApi } from '../model/useModelApi';
import { useStep } from 'context/step/StepProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
  featuresFlagsActions,
  featuresFlagsSelectors,
  hostSettingsSelectors,
  configurationSelectors,
  streamingSelectors,
  modelSelectors,
  sharedSelectors,
  authSelectors,
  structureActions,
} from 'store';
import { notification } from '../../utils/notification';
import { UrlTransform } from '../../utils/urlTransform';
import { useConfiguration } from '../configuration/ConfigurationProvider';
import { useCountry } from '../country/CountryProvider';
import { useCustomer } from '../customer/CustomerProvider';
import { detectDynamicPageFeaturesByPathname } from '../feature/utils/utils';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useFeature } from 'context/feature/FeatureProvider';
import { StreamingEventType } from 'context/streaming/types';
import { useAuthApi } from 'context/auth/useAuthApi';
import { usePrerenderSession } from './AuthenticatedPrerenderSessionProvider';
import { decodeModelNumber } from 'context/model/utils/modelNumber';
import {
  ConfigurationItemFields,
  CustomerFields,
  PriceListFields,
  StructureFields,
  StructureLabel,
  StructureLabelFields,
  ConfigurationListItemFields,
  LanguageFields,
} from 'types/vendor';
import { get } from 'utils';
import { getTtParams } from 'utils/format';
import { filterSubStepsLabels } from 'context/step/utils';
import { usePriceList } from 'context/priceList/PriceListProvider';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';
import { useStructureApi } from 'context/structure/useStructureApi';
import useBuildStockFromUrl from './useBuildStockFromUrl';
import useUnsetCurrentConfiguration from 'hooks/useUnsetCurrentConfiguration';
import { useAuthenticatedLoadingStatusesUpdateEffect } from 'providers/useAuthenticatedLoadingStatusesUpdateEffect';

interface ContextValue {
  handleNewConfiguration(): void;
}

const SessionContext = React.createContext<ContextValue | undefined>(undefined);

/** Context for session restore and some specific session actions
 * For authenticated state */
const AuthenticatedSessionProvider: FC<PropsWithChildren<{ value?: ContextValue }>> =
  props => {
    const history = useHistory();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const { configurationNumberChanged } = usePrerenderSession();
    useLanguage();
    useCountry();
    const customerId = useSelector(hostSettingsSelectors.getCustomerId);
    const isServerDown = useSelector(sharedSelectors.getIsServerDown);
    const { setQueryParams, queryValues, query } = useQuery();
    const { restoreRelations, setRelationCustomer, relations } = useRelations();
    const { getModel } = useModelApi();
    const { setup, sendMessage } = useStreaming();

    const {
      isNewConfiguration,
      getConfigurationDetailsById,
      restoreConfigurationProvider,
    } = useConfiguration();
    const { getStructure } = useStructureApi();
    const { languages } = useLanguage();
    const { getCustomerById } = useCustomer();
    const { isFeatureEnabled } = useFeature();
    const { handleStepClick } = useStep();
    const { logout } = useAuthApi();
    const { selectedPriceListItem } = usePriceList();
    const currentLanguageCode = useCurrentLanguageCode();

    useBuildStockFromUrl();

    const {
      configurationDetails,
      configurationNumber,
      modelCatalogCode,
      configurationModelName,
      modelNumber,
    } = useSelector(configurationSelectors.getAll);
    const authToken = useSelector(authSelectors.getAuthToken);
    const username = useSelector(authSelectors.getUsername);
    const { unsetCurrentConfiguration } = useUnsetCurrentConfiguration();

    const {
      isConfigurationComplete,
      isModelConfigured,
      isConfigurationCreatedFromStock,
    } = useSelector(modelSelectors.getAll);
    const isWholePageLoading = useSelector(sharedSelectors.getIsLoaderVisible);

    /** Solves a problem of changes to query while login.
     * So app relies on query received after logging in. */
    const localInitialQueryValues = useMemo(() => {
      return queryValues;
      // eslint-disable-next-line ,react-hooks/exhaustive-deps
    }, []);

    const globalFeatures = useSelector(featuresFlagsSelectors.getGlobalFeatures);
    const configurator = useSelector(streamingSelectors.getConfigurator);

    const dynamicStepsFeatures = useSelector(
      featuresFlagsSelectors.getDynamicStepsFeatures,
    );
    const isShowModelNumberHeaderFeatureEnabled = isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.allowShowModelNumberHeader,
    });
    const isStreamingFeatureEnabled = isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.allowStreaming,
    });

    useAuthenticatedLoadingStatusesUpdateEffect();

    useEffect(() => {
      if (!isStreamingFeatureEnabled || isConfigurationCreatedFromStock) return;

      setup();
    }, [setup, isStreamingFeatureEnabled, isConfigurationCreatedFromStock]);

    // + copypaste from header
    const firstCustomer = relations[0]?.customer;

    const userName = useMemo(() => {
      const secondName = get(firstCustomer, CustomerFields.name2, '');
      const firstName = get(firstCustomer, CustomerFields.name, '');

      return [firstName, secondName].filter(Boolean).join(' ');
    }, [firstCustomer]);
    // - copypaste from header

    useEffect(() => {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'global',
          data: {
            userName,
            modelNumber,
            modelName: isShowModelNumberHeaderFeatureEnabled
              ? configurationModelName
              : null,
            configurationNumber,
            languageCode: currentLanguageCode.iso,
          },
        },
      });
    }, [
      sendMessage,
      userName,
      modelNumber,
      configurationModelName,
      isShowModelNumberHeaderFeatureEnabled,
      configurationNumber,
      currentLanguageCode,
    ]);

    useEffect(() => {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: {
            currentlyPreviewedPackage: null,
          },
        },
      });
    }, []);

    useEffect(() => {
      if (!isServerDown) return;

      setTimeout(() => {
        logout({ keepConfigurationNumber: true, instant: true }, () => {
          const [terminal] = configurator?.terminals ?? [];

          if (terminal) {
            sendMessage({
              type: StreamingEventType.STOP_STREAMING_FOR_TERMINAL,
              data: {
                customerId: terminal.customerId,
              },
            });
          }

          sendMessage({
            type: StreamingEventType.CLEAR_SLOTS,
          });
        });
      }, 3000);
    }, [configurator, isServerDown, logout, sendMessage]);

    useEffect(() => {
      if (configurationNumberChanged) {
        sendMessage({
          type: StreamingEventType.CLEAR_SLOTS,
        });
      }
    }, [sendMessage, configurationNumberChanged]);

    // It's not spotless, but when language changes
    // causes effect to trigger excessively
    const [isInitialConfigurationNumberSettled, setIsInitialConfigurationNumberSettled] =
      useState<boolean>();
    /** Check if configurationNumbers from query and storage are different.
     * If they are - offer user to work with configuration from query.
     */
    useEffect(() => {
      if (isInitialConfigurationNumberSettled) return;
      const storedConfigurationNumber = loadFromLocalStorage<number>(
        STORAGE_KEYS.configurationNumber,
      );

      let restoredConfigurationNumber: number | string | null | undefined =
        storedConfigurationNumber || localInitialQueryValues.configurationNumber;

      if (
        localInitialQueryValues.configurationNumber &&
        storedConfigurationNumber &&
        Number(localInitialQueryValues.configurationNumber) !==
          Number(storedConfigurationNumber)
      ) {
        restoredConfigurationNumber = localInitialQueryValues.configurationNumber;
      }

      if (restoredConfigurationNumber) {
        saveInLocalStorage(STORAGE_KEYS.configurationNumber, restoredConfigurationNumber);
        restoreConfigurationProvider(restoredConfigurationNumber);
      }
      setIsInitialConfigurationNumberSettled(true);
    }, [
      isInitialConfigurationNumberSettled,
      localInitialQueryValues.configurationNumber,
      restoreConfigurationProvider,
    ]);

    useEffect(() => {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'global',
          data: {
            isPublished: isConfigurationComplete,
          },
        },
      });
    }, [sendMessage, isConfigurationComplete]);

    useEffect(() => {
      if (!configurationNumber || !isStreamingFeatureEnabled) return;

      const structureParams = getTtParams({
        [CustomerFields.id]: username,
        [PriceListFields.catalog]: get(
          get(configurationDetails, 'configuration'),
          ConfigurationItemFields.catalogCode,
        ),
        CDlang: currentLanguageCode.system,
      });

      getStructure(structureParams).then(({ response }) => {
        if (!response) return;
        const subStepsLabels: StructureLabel[] = filterSubStepsLabels(
          response[StructureFields.labels],
        );

        dispatch(structureActions.setTranslatedModelBuildSteps(subStepsLabels));

        if (!modelNumber || !isModelConfigured) return;

        const selectedModel = decodeModelNumber(modelNumber, subStepsLabels, {
          keyField: StructureLabelFields.description,
        });

        const modelSelections = Object.entries(selectedModel).reduce(
          (res, [type, value], index) => {
            res[index] = { type, value };
            return res;
          },
          {},
        );

        sendMessage({
          type: StreamingEventType.EMIT_SLOT_CHANGE,
          data: {
            name: 'model',
            data: {
              modelYear: selectedPriceListItem?.[PriceListFields.version],
              selections: modelSelections,
            },
          },
        });
      });
    }, [
      configurationNumber,
      configurationDetails,
      getStructure,
      modelNumber,
      selectedPriceListItem,
      sendMessage,
      username,
      isStreamingFeatureEnabled,
      currentLanguageCode,
      dispatch,
      isModelConfigured,
    ]);

    /** Temp. Created to reduce amount of calls for {@link restoreRelations}. */
    const globalFeatures_stringified = JSON.stringify(globalFeatures);
    /**
     * Restore relations
     * It also triggers when offer is just being created
     */
    useEffect(() => {
      restoreRelations(configurationDetails, globalFeatures ?? {}, t, customerId);
      // eslint-disable-next-line ,react-hooks/exhaustive-deps
    }, [configurationNumber, globalFeatures_stringified]);

    /** + Request initial structure to have dynamic steps and their settings. */
    const currLanguageCode = useMemo<string | undefined>(() => {
      const currentLanguage = languages.find(language => {
        const langCode = String(language[LanguageFields.shortLanguageCode]).toLowerCase();
        return langCode === i18n.language;
      });
      return currentLanguage?.[LanguageFields.languageCode];
    }, [languages, i18n.language]);

    useEffect(() => {
      if (!modelCatalogCode) return;

      const params = getTtParams({
        [ConfigurationListItemFields.customerNumber]: username,
        [ConfigurationListItemFields.catalogCode]: modelCatalogCode,
        [LanguageFields.languageCode]: currLanguageCode,
      });

      // preserved timeout just in case,
      // should prevent multiple calls for effect in row,
      // as it has many deps
      const timeout = setTimeout(() => getStructure(params, { preserve: true }));
      return () => clearTimeout(timeout);
    }, [getStructure, currLanguageCode, modelCatalogCode, username]);
    /** - Request initial structure to have dynamic steps and their settings. */

    /** Update current page features flags */
    useEffect(() => {
      const currentPageFeaturesFlags = detectDynamicPageFeaturesByPathname(
        dynamicStepsFeatures,
        location.pathname,
      );
      dispatch(featuresFlagsActions.setCurrentPageFeatures(currentPageFeaturesFlags));
    }, [dispatch, dynamicStepsFeatures, location.pathname]);

    /** restore model, refetch on changes */
    useEffect(() => {
      getModel().then(model => {
        if (!model) return;
        const messages: Message[] | undefined = model[ModelFields.message];
        if (messages?.length) notification.open({ message: messages });
      });
    }, [getModel, configurationNumber]);

    /** Update query values */
    useEffect(() => {
      setQueryParams({
        key: URL_QUERY_PARAMS.configurationNumber,
        value: configurationNumber,
      });
    }, [location.pathname, configurationNumber, setQueryParams]);

    useEffect(() => {
      if (isModelConfigured && configurationNumber && !configurationModelName) {
        getConfigurationDetailsById({ configurationNumber });
      }
    }, [
      configurationModelName,
      configurationNumber,
      getConfigurationDetailsById,
      isModelConfigured,
    ]);

    const handleNewConfiguration = useCallback<
      ContextValue['handleNewConfiguration']
    >(() => {
      unsetCurrentConfiguration();

      sendMessage({
        type: StreamingEventType.CLEAR_SLOTS,
      });

      handleStepClick(0);
    }, [handleStepClick, sendMessage, unsetCurrentConfiguration]);

    /** Fill relations depending on query. */
    useEffect(() => {
      if (isWholePageLoading || !queryValues.presetRelations.length) return;

      if (isNewConfiguration) {
        (async () => {
          for (const relation of queryValues.presetRelations) {
            const customer = await getCustomerById(String(relation.customerId));
            setRelationCustomer(relation.id, customer);
          }
        })();
      }

      const newQuery = { ...query };

      queryValues.presetRelations.forEach(({ key }) => delete newQuery[key]);

      history.replace({
        search: UrlTransform.stringifyQuery(newQuery),
      });
    }, [
      getCustomerById,
      history,
      isNewConfiguration,
      isWholePageLoading,
      query,
      queryValues.presetRelations,
      setRelationCustomer,
    ]);

    // Listen to changes in local storage in order to adapt to actions from other browser tabs
    useEffect(() => {
      const handleChange = (): void => {
        const storedToken = loadFromLocalStorage<string>(STORAGE_KEYS.authToken);

        // if user logged out on another tab - reload current tab
        if (authToken && (!storedToken || storedToken !== authToken)) {
          window.location.reload();
        }
      };
      window.addEventListener('storage', handleChange, false);
      return (): void => {
        window.removeEventListener('storage', handleChange);
      };
    }, [authToken]);

    const value = useMemo(
      () => ({
        handleNewConfiguration,
      }),
      [handleNewConfiguration],
    );

    return <SessionContext.Provider value={value} {...props} />;
  };

const useSession = (): ContextValue => {
  const context = useContext(SessionContext);

  if (context === undefined) {
    throw new Error(
      `${useSession.name} must be used within an ${AuthenticatedSessionProvider.name}`,
    );
  }

  return context;
};

export { AuthenticatedSessionProvider, useSession };

import styled from 'styled-components';
import { InputNumber } from 'antd';
import { basicInputStyle, inputStyles } from './styles';

export const ScInputNumber = styled(InputNumber)`
  width: 100%;
  ${basicInputStyle}

  &.ant-input-number {
    .ant-input-number-handler-wrap {
      bottom: 1px;
      top: 1px;
      right: 1px;
      height: auto;
    }
    .ant-input-number-handler-down-inner,
    .ant-input-number-handler-up-inner {
      color: ${({ theme }) => theme.mainColor};
    }

    .ant-input-number-input {
      height: unset;
      padding: unset;
      font-weight: inherit;
    }
  }

  .ant-form-item-has-feedback & .ant-input-number-handler-wrap {
    right: 2.5em;
    .ant-input-number-handler {
      border-right: 1px solid ${({ theme }) => theme.colors.lightSilver};
    }
  }

  &.ant-input-number.ant-input-number-sm {
    height: ${({ theme }) => theme.inputHeight_sm};
    padding: 0.25em;
  }

  &.ant-input-affix-wrapper {
    padding-right: 0;
  }

  &.dark-border {
    border-color: ${({ theme }) => theme.mainBorderColor};
  }

  &.discount-input {
    border-top: 1px solid ${({ theme }) => theme.mainBorderColor};
    border-left: 1px solid ${({ theme }) => theme.mainBorderColor};
    border-bottom: 1px solid ${({ theme }) => theme.mainBorderColor};
    border-right: none;
  }
`;

export const ScInputNumberContainer = styled.div`
  .form-input-disabled {
    color: ${({ theme }) => theme.colors.dustyGray};
  }

  .discountColor {
    color: ${({ theme }) => theme.discountColor};
  }

  .small {
    padding: 0;
    padding-left: 0;
    padding-right: 1em;
    width: 100%;
    max-width: 3.5em;
    min-width: 3.75em;
    height: 1.875em;
    display: flex;
    align-items: center;
  }

  &.borderless .ant-input-number {
    border: none;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;

    input {
      padding: 0;
      font-size: 1em;
      font-weight: 600;
    }
  }

  ${inputStyles}
`;

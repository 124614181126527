import {
  SoftOfferInsurancePolicyHolder,
  SoftOfferInsurancePolicyHolderFields,
} from 'types/vendor';

export const getInitialValues = (
  initials: Partial<SoftOfferInsurancePolicyHolder>,
): SoftOfferInsurancePolicyHolder =>
  ({
    [SoftOfferInsurancePolicyHolderFields.id]: 0,
    [SoftOfferInsurancePolicyHolderFields.email]: '',
    [SoftOfferInsurancePolicyHolderFields.job]: '',
    [SoftOfferInsurancePolicyHolderFields.birthdayAt]: null,
    [SoftOfferInsurancePolicyHolderFields.lastName]: '',
    [SoftOfferInsurancePolicyHolderFields.firstName]: '',
    [SoftOfferInsurancePolicyHolderFields.language]: '',
    [SoftOfferInsurancePolicyHolderFields.licence]: null,
    [SoftOfferInsurancePolicyHolderFields.licenceCategoryCode]: '',
    [SoftOfferInsurancePolicyHolderFields.locality]: '',
    [SoftOfferInsurancePolicyHolderFields.number]: '',
    [SoftOfferInsurancePolicyHolderFields.box]: '',
    [SoftOfferInsurancePolicyHolderFields.personType]: '',
    [SoftOfferInsurancePolicyHolderFields.phone]: '',
    [SoftOfferInsurancePolicyHolderFields.phoneGsm]: '',
    [SoftOfferInsurancePolicyHolderFields.sex]: '',
    [SoftOfferInsurancePolicyHolderFields.street]: '',
    [SoftOfferInsurancePolicyHolderFields.zipCode]: '',
    ...initials,
  } as SoftOfferInsurancePolicyHolder);

import { SoftOfferVehicleFields, SoftOfferVehicle } from 'types/vendor';

export const getInitialValues = (
  initials?: Partial<SoftOfferVehicle>,
): SoftOfferVehicle =>
  ({
    [SoftOfferVehicleFields.id]: 0,
    [SoftOfferVehicleFields.configurationNumber]: '',
    [SoftOfferVehicleFields.changedAt]: null,
    [SoftOfferVehicleFields.changedBy]: '',

    [SoftOfferVehicleFields.guaranteeCapital]: 0,
    [SoftOfferVehicleFields.purchaseAt]: null,
    [SoftOfferVehicleFields.maximumMileagePerYearCategory]: '',
    [SoftOfferVehicleFields.odometerActualReadingAt]: null,
    [SoftOfferVehicleFields.safetyFeaturesCount]: 0,
    [SoftOfferVehicleFields.odometerActualReadingQuantity]: 0,
    [SoftOfferVehicleFields.brand]: '',
    [SoftOfferVehicleFields.model]: '',
    [SoftOfferVehicleFields.chassis]: '',
    [SoftOfferVehicleFields.immatriculationPlate]: '',
    [SoftOfferVehicleFields.fuelCode]: '',
    [SoftOfferVehicleFields.type]: '',
    [SoftOfferVehicleFields.power]: 0,
    [SoftOfferVehicleFields.isAllRoad]: false,
    [SoftOfferVehicleFields.isConvertibleIndicator]: false,
    [SoftOfferVehicleFields.firstImmatriculationAt]: null,
    [SoftOfferVehicleFields.contractStartAt]: null,
    [SoftOfferVehicleFields.usage]: '',
    [SoftOfferVehicleFields.usage_lightTruck]: '',
    [SoftOfferVehicleFields.usage_other]: '',
    [SoftOfferVehicleFields.trailer1Type]: '',
    [SoftOfferVehicleFields.trailer1Brand]: '',
    [SoftOfferVehicleFields.trailer1Model]: '',
    [SoftOfferVehicleFields.trailer1Plate]: '',
    [SoftOfferVehicleFields.trailer1Chassis]: '',
    [SoftOfferVehicleFields.maximumAuthorisedWeightQuantity]: 0,
    [SoftOfferVehicleFields.enterpriseType]: '',
    [SoftOfferVehicleFields.alarmCode]: '',
    [SoftOfferVehicleFields.kindOfBuy]: '',
    [SoftOfferVehicleFields.remarque]: '',
    ...initials,
  } as SoftOfferVehicle);

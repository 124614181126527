import { FinancingCarInfo, FinancingCarInfoFields } from 'types/vendor';
import { STATUSES } from '.';

export const getInitialValues = (
  initials?: Partial<FinancingCarInfo>,
): FinancingCarInfo =>
  ({
    [FinancingCarInfoFields.id]: 0,
    [FinancingCarInfoFields.brand]: '',
    [FinancingCarInfoFields.modelName]: '',
    [FinancingCarInfoFields.modelNumber]: '',
    [FinancingCarInfoFields.fuelCode]: '',
    [FinancingCarInfoFields.fuelCode2]: '',
    [FinancingCarInfoFields.status]: STATUSES[0].value,
    [FinancingCarInfoFields.financialNumber]: '',
    [FinancingCarInfoFields.firstRegistrationAt]: '',
    [FinancingCarInfoFields.salePrice]: '',
    ...initials,
  } as FinancingCarInfo);

import {
  SoftOfferUsualDriverFields,
  SoftOfferOptionsKeys,
  SoftOfferUsualDriver,
  OlistItemOption,
  OlistItemOptionFields,
} from 'types/vendor';
import { mapToSelectOptions } from 'utils/formField/mapToSelectOptions';
import React, { FC, useEffect, useMemo } from 'react';
import { Input } from 'components/form/Input';
import FormField from 'components/form/formik/FormField';
import Select from 'components/form/Selector';
import Checkbox from 'components/form/Checkbox';
import { ScCheckboxFormFieldWrapper } from '../styles';
import { DatePicker } from 'components/form/DatePicker';
import { Status } from 'utils/types';
import { DATE_FORMAT } from 'common/constants';
import { useSelector } from 'react-redux';
import { softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { Form, FormikProvider, useFormik } from 'formik';
import { getSchema } from './schema';
import { getInitialValues } from './initial';
import Footer from '../Footer';
import { Col, Row } from 'antd';
import { isFieldRequired } from 'utils/is-field-required';
import { CountryFields } from '@hypercharge/xdms-client/lib/types';
import { MobileInput, MobileInputCountry } from 'components/form/MobileInput';
import { useCountry } from 'context/country/CountryProvider';
import { InputCityZipField } from 'components/form/formik/InputCityZipField';

const ROW_SPACES: [number, number] = [20, 0];
const SUB_ROW_SPACES: [number, number] = [16, 0];

interface Props {
  isCreate: boolean;
  isDisabled: boolean;
  defaultCountryCode?: string;
  initialValues?: SoftOfferUsualDriver;
  onSubmit(values: SoftOfferUsualDriver): void;
  setIsTabLocked?(value: boolean): void;
}

const UsualDriverForm: FC<Props> = ({
  onSubmit,
  initialValues,
  defaultCountryCode,
  isCreate,
  isDisabled,
  setIsTabLocked,
}) => {
  const { t } = useTranslation();

  const { countries } = useCountry();

  const options = useSelector(softOffersSelectors.getOptions);
  const status = useSelector(softOffersSelectors.getDetailsStatus);

  const isLoading = useMemo<boolean>(() => status === Status.Loading, [status]);

  const countriesPhoneCodeList = useMemo<MobileInputCountry[]>(
    () =>
      countries.map(country => country[CountryFields.countryCode] as MobileInputCountry),
    [countries],
  );

  const languages = options?.[SoftOfferOptionsKeys.DriverLanguage]?.options ?? [];
  const sexes = options?.[SoftOfferOptionsKeys.DriverSex]?.options ?? [];
  const affinities = options?.[SoftOfferOptionsKeys.DriverAffinity]?.options ?? [];
  const jobs = options?.[SoftOfferOptionsKeys.DriverJob]?.options ?? [];
  const licenceCategoryCodes =
    options?.[SoftOfferOptionsKeys.DriverLicenceCategoryCode]?.options ?? [];

  const schema = getSchema(t);

  const initialData = useMemo(() => {
    const defaultValues = getInitialValues({
      [SoftOfferUsualDriverFields.driverLanguage]:
        options?.[SoftOfferOptionsKeys.DriverLanguage]?.initialOption,
      [SoftOfferUsualDriverFields.driverSex]:
        options?.[SoftOfferOptionsKeys.DriverSex]?.initialOption,
      [SoftOfferUsualDriverFields.driverAffinity]:
        options?.[SoftOfferOptionsKeys.DriverAffinity]?.initialOption,
      [SoftOfferUsualDriverFields.driverJob]:
        options?.[SoftOfferOptionsKeys.DriverJob]?.initialOption,
      [SoftOfferUsualDriverFields.driverLicenceCategoryCode]:
        options?.[SoftOfferOptionsKeys.DriverLicenceCategoryCode]?.initialOption,
    });

    if (isCreate) {
      return {
        ...defaultValues,
        ...initialValues,
      };
    }

    return initialValues ?? defaultValues;
  }, [initialValues, isCreate, options]);

  const formProps = useFormik({
    initialValues: initialData,
    onSubmit: values => onSubmit(values),
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { values, dirty } = formProps;

  useEffect(() => {
    setIsTabLocked?.(dirty);
  }, [dirty, setIsTabLocked]);

  return (
    <FormikProvider value={formProps}>
      <Form>
        <Row gutter={ROW_SPACES}>
          <Col md={12}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverLastName}
                  name={SoftOfferUsualDriverFields.driverLastName}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferUsualDriverFields.driverLastName,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_LAST_NAME')}
                  data-testid="soft-offer-step-form-last-name-field"
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverFirstname}
                  name={SoftOfferUsualDriverFields.driverFirstname}
                  component={Input}
                  required={isFieldRequired(
                    schema,
                    SoftOfferUsualDriverFields.driverFirstname,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_FIRST_NAME')}
                  data-testid="soft-offer-step-form-first-name-field"
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverBirthdayAt}
                  name={SoftOfferUsualDriverFields.driverBirthdayAt}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(
                    schema,
                    SoftOfferUsualDriverFields.driverBirthdayAt,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_BIRTHDAY')}
                  data-testid="soft-offer-step-form-birthday-field"
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverSex}
                  name={SoftOfferUsualDriverFields.driverSex}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(sexes, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverSex)}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_GENDER')}
                  data-testid="soft-offer-step-form-gender-field"
                />
              </Col>
            </Row>

            <InputCityZipField
              inputZipProps={{
                id: SoftOfferUsualDriverFields.driverZipCode,
                name: SoftOfferUsualDriverFields.driverZipCode,
                required: isFieldRequired(
                  schema,
                  SoftOfferUsualDriverFields.driverZipCode,
                ),
                disabled: isDisabled,
                label: t('SOFT_OFFER_ZIPCODE'),
              }}
              inputCityProps={{
                id: SoftOfferUsualDriverFields.driverLocality,
                name: SoftOfferUsualDriverFields.driverLocality,
                required: isFieldRequired(
                  schema,
                  SoftOfferUsualDriverFields.driverLocality,
                ),
                disabled: isDisabled,
                label: t('SOFT_OFFER_TOWN'),
              }}
            />

            <FormField
              id={SoftOfferUsualDriverFields.driverStreet}
              name={SoftOfferUsualDriverFields.driverStreet}
              component={Input}
              required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverStreet)}
              disabled={isDisabled}
              label={t('SOFT_OFFER_STREET')}
              data-testid="soft-offer-step-form-street-field"
            />

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverNr}
                  name={SoftOfferUsualDriverFields.driverNr}
                  component={Input}
                  required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverNr)}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_NR')}
                  data-testid="soft-offer-step-form-nr-field"
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverBox}
                  name={SoftOfferUsualDriverFields.driverBox}
                  component={Input}
                  required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverBox)}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_BOX')}
                  data-testid="soft-offer-step-form-box-field"
                />
              </Col>
            </Row>

            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverTel}
                  name={SoftOfferUsualDriverFields.driverTel}
                  component={MobileInput}
                  required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverTel)}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_PHONE')}
                  data-testid="soft-offer-step-form-phone-field"
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverGsm}
                  name={SoftOfferUsualDriverFields.driverGsm}
                  component={MobileInput}
                  required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverGsm)}
                  countries={countriesPhoneCodeList}
                  defaultCountry={defaultCountryCode as MobileInputCountry}
                  international={true}
                  addInternationalOption={false}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_PHONE_GSM')}
                  data-testid="soft-offer-step-form-gsm-field"
                />
              </Col>
            </Row>

            <ScCheckboxFormFieldWrapper>
              <FormField
                id={SoftOfferUsualDriverFields.driverIsHolder}
                name={SoftOfferUsualDriverFields.driverIsHolder}
                component={Checkbox}
                required={isFieldRequired(
                  schema,
                  SoftOfferUsualDriverFields.driverIsHolder,
                )}
                checked={values[SoftOfferUsualDriverFields.driverIsHolder]}
                disabled={isDisabled}
                label={t('SOFT_OFFER_INSURANCE_POLICY_HOLDER_IS_PRIMARY_DRIVER')}
                data-testid="soft-offer-step-form-insurance-policy-holder-field"
              />
            </ScCheckboxFormFieldWrapper>
          </Col>
          <Col md={12}>
            <Row gutter={SUB_ROW_SPACES}>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverFax}
                  name={SoftOfferUsualDriverFields.driverFax}
                  component={Input}
                  required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverFax)}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_FAX')}
                  data-testid="soft-offer-step-form-fax-field"
                />
              </Col>
              <Col md={12}>
                <FormField
                  id={SoftOfferUsualDriverFields.driverLanguage}
                  name={SoftOfferUsualDriverFields.driverLanguage}
                  component={Select}
                  loading={isLoading}
                  options={mapToSelectOptions<OlistItemOption>(languages, {
                    label: OlistItemOptionFields.label,
                    value: OlistItemOptionFields.value,
                  })}
                  required={isFieldRequired(
                    schema,
                    SoftOfferUsualDriverFields.driverLanguage,
                  )}
                  disabled={isDisabled}
                  label={t('SOFT_OFFER_LANGUAGE')}
                  data-testid="soft-offer-step-form-language-field"
                />
              </Col>
            </Row>

            <FormField
              id={SoftOfferUsualDriverFields.driverEmail}
              name={SoftOfferUsualDriverFields.driverEmail}
              component={Input}
              required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverEmail)}
              disabled={isDisabled}
              label={t('SOFT_OFFER_EMAIL')}
              data-testid="soft-offer-step-form-email-field"
            />
            <FormField
              id={SoftOfferUsualDriverFields.driverJob}
              name={SoftOfferUsualDriverFields.driverJob}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(jobs, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(schema, SoftOfferUsualDriverFields.driverJob)}
              disabled={isDisabled}
              label={t('SOFT_OFFER_JOB')}
              data-testid="soft-offer-step-form-job-field"
            />
            <FormField
              id={SoftOfferUsualDriverFields.driverAffinity}
              name={SoftOfferUsualDriverFields.driverAffinity}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(affinities, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(
                schema,
                SoftOfferUsualDriverFields.driverAffinity,
              )}
              disabled={isDisabled}
              label={t('SOFT_OFFER_AFFINITY')}
              data-testid="soft-offer-step-form-affinity-field"
            />

            <FormField
              id={SoftOfferUsualDriverFields.driverLicenceAt}
              name={SoftOfferUsualDriverFields.driverLicenceAt}
              component={DatePicker}
              format={DATE_FORMAT}
              required={isFieldRequired(
                schema,
                SoftOfferUsualDriverFields.driverLicenceAt,
              )}
              disabled={isDisabled}
              label={t('SOFT_OFFER_ISSUE_DATE_DEFINITIVE_DRIVING_LIC')}
              data-testid="soft-offer-step-form-definitive-driving-lic-field"
            />

            <FormField
              id={SoftOfferUsualDriverFields.driverLicenceCategoryCode}
              name={SoftOfferUsualDriverFields.driverLicenceCategoryCode}
              component={Select}
              loading={isLoading}
              options={mapToSelectOptions<OlistItemOption>(licenceCategoryCodes, {
                label: OlistItemOptionFields.label,
                value: OlistItemOptionFields.value,
              })}
              required={isFieldRequired(
                schema,
                SoftOfferUsualDriverFields.driverLicenceCategoryCode,
              )}
              disabled={isDisabled}
              label={t('SOFT_OFFER_ISSUE_DATE_DEFINITIVE_DRIVING_LIC_CODE')}
              data-testid="soft-offer-step-form-definitive-driving-lic-field-code"
            />

            <ScCheckboxFormFieldWrapper>
              <FormField
                id={SoftOfferUsualDriverFields.isOccasionalDriversEnabled}
                name={SoftOfferUsualDriverFields.isOccasionalDriversEnabled}
                component={Checkbox}
                required={isFieldRequired(
                  schema,
                  SoftOfferUsualDriverFields.isOccasionalDriversEnabled,
                )}
                checked={values[SoftOfferUsualDriverFields.isOccasionalDriversEnabled]}
                disabled={isDisabled}
                label={t('SOFT_OFFER_OCCASIONAL_DRIVERS_ENABLED')}
                data-testid="soft-offer-step-form-occasional-drivers-enabled-field"
              />
            </ScCheckboxFormFieldWrapper>
          </Col>
        </Row>

        <Footer isCreate={isCreate} isSubmitDisabled={isDisabled} />
      </Form>
    </FormikProvider>
  );
};

export default UsualDriverForm;
